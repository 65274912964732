import { CheckboxCell } from '@one/components/common/CheckBoxCell'
import { NumberCell, NumberCellRO } from '@one/components/common/NumberCell'
import { HkmEnum } from '@one/enums/HkmEnum'
import { VkPreisStaffelTyp } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { Column, DataTable } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { SelectEnumField } from '@utils/ui/fields/SelectEnumField'
import { useMemo } from 'react'
import { VPEField } from './fields/VPEField'

export const VkPreisStaffelTable = ({
  preise,
  onStaffelChange,
  onRemoveStaffel,
  readonly,
  mengeneinheiten
}) => {
  const { en } = useEnums()

  const actions = useMemo(
    (): DataTableAction[] => [
      !readonly && {
        icon: 'delete',
        tooltip: 'Staffel löschen',
        direct: true,
        handler: onRemoveStaffel
      }
    ],
    [onRemoveStaffel, readonly]
  )

  const columns = useMemo<Column[]>(
    () => [
      {
        field: 'vpeinheitId',
        header: 'VPE',
        align: 'left',
        sortable: false,
        width: '3em',
        body: (row: any, { column }) => (
          <VPEField
            mengeneinheiten={mengeneinheiten}
            model={row}
            field={column.field as string}
            onChange={onStaffelChange}
            readonly={readonly}
          />
        )
      },
      {
        field: 'vpmenge',
        header: 'Ab VPE',
        align: 'right',
        sortable: false,
        width: '7em',
        body: (row: any, { column }) => (
          <NumberCell
            model={row}
            field={column.field as string}
            readonly={readonly}
            onChange={onStaffelChange}
          />
        )
      },
      {
        field: 'staffelmenge',
        header: 'Ab Menge',
        align: 'right',
        sortable: true,
        body: (row: any, { column }) => <NumberCellRO model={row} field={column.field as string} />
      },
      {
        field: 'fix',
        header: 'Fix',
        align: 'center',
        sortable: false,
        body: (row: any, { column }) => (
          <CheckboxCell
            size="small"
            model={row}
            field={column.field as string}
            // value={row.brutto}
            onChange={onStaffelChange}
            readonly={readonly}
          />
        )
      },

      {
        field: 'staffelTyp',
        header: 'Typ',
        align: 'left',
        width: '8em',
        sortable: false,
        body: (row: any, { column }) => (
          <SelectEnumField
            name="staffelTyp"
            enumType={HkmEnum.VkPreisStaffelTyp}
            value={row.staffelTyp}
            onChange={(e) => onStaffelChange(row, column.field, e.target.value)}
            disabled={readonly}
            fullWidth
            title={row.staffelTyp && en(HkmEnum.VkPreisStaffelTyp, row.staffelTyp).descr}
          />
        )
      },
      {
        field: 'preis',
        header: 'Preis',
        align: 'right',
        sortable: false,
        width: '6em',
        body: (row: any, { column }) => (
          <NumberCell
            model={row}
            field={column.field as string}
            readonly={readonly || row.staffelTyp !== VkPreisStaffelTyp.ABSOLUT}
            onChange={onStaffelChange}
            min={0}
          />
        )
      },
      {
        field: 'staffelRabattAbs',
        header: 'R-Abs',
        align: 'right',
        sortable: false,
        width: '6em',
        body: (row: any, { column }) =>
          row.staffelTyp === VkPreisStaffelTyp.RABATT_ABS ? (
            <NumberCell
              model={row}
              field={column.field as string}
              readonly={readonly}
              onChange={onStaffelChange}
              min={0}
            />
          ) : null
      },
      {
        field: 'staffelRabattProz',
        header: 'R-Prz %',
        align: 'right',
        sortable: false,
        width: '6em',
        body: (row: any, { column }) =>
          row.staffelTyp === VkPreisStaffelTyp.RABATT_PROZ ||
          row.staffelTyp === VkPreisStaffelTyp.RABATT_PROZ_VERD ? (
            <NumberCell
              model={row}
              field={column.field as string}
              readonly={readonly}
              onChange={onStaffelChange}
              allowNegative
            />
          ) : null
      }
    ],
    [en, mengeneinheiten, onStaffelChange, readonly]
  )

  return (
    <DataTable
      name="VkPreisStaffelTable"
      columns={columns}
      value={preise}
      actions={actions}
      selectMode="none"
      focustype="none"
      size="slim"
      initialOrderBy="staffelmenge"
      emptyMessage="Keine Staffel vorhanden"
    />
  )
}
