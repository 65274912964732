import { MessageID } from '@one/MessageIDs'
import {
  AsyncTaskResponseJson,
  MessageJson,
  MessageSeverity,
  UseCaseStateJson
} from '@one/typings/apiTypings'
import { AxiosInstance } from 'axios'
import {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useReducer,
  useRef
} from 'react'
import isEqual from 'react-fast-compare'
import { useApiCaller } from './apicaller'
import { isAsyncTaskActive, isAsyncTaskStatusActive, useAsyncTaskPoller } from './asynctask'
import { debugLog, errorLog } from './logging'
import { notifyObservers } from './observer'
import { AppRouteCtx } from './ui/App/AppRouteCtx'
import { AppSwitchCtx } from './ui/App/AppSwitchCtx'
import { useSnackbarEx } from './ui/snackbarex'
import { UILockMode, UILockType } from './uilock'
import {
  clearTimer,
  dataFromEvent,
  fieldsToArray,
  isEmptyEx,
  isObject,
  isString,
  KeyValuePair,
  messageToErrors,
  restartTimer,
  updateObjectField
} from './utils'

export interface HasUseCaseState {
  state?: UseCaseStateJson
}

export type SaveCallBackProps<T> = {
  then: (data: T, asyncTaskProerties: any) => true | false | void
  catch?: (error: any) => void
}

export interface ModelAction extends Record<string, any> {
  type: string
}

export enum ModelState {
  removed = -1,
  init = 0,
  ready = 1,
  error = 2,
  loading = 3,
  saving = 4,
  posting = 5
}

export enum ModelMgrEventAction {
  UPDATED = 'UPDATED',
  REMOVED = 'REMOVED'
}

const empty = {}

const initialState = {
  state: ModelState.init,
  undoStack: new Array(100).fill(null),
  undoPos: 0,
  undoTS: Date.now()
}

export type OnSave<T> = (model: T, asyncProperties: any) => true | false | void

export type UpdModelType<T> = Partial<T> | ((prevState: T) => T)

export type UpdModelFn<T> = (params: UpdModelType<T>) => void

export type ValueChangeType<T> = any | ((prevState: T) => T)

export type ValueChangeFn<T> = (params: ValueChangeType<T>) => void

export type ChangeFn = (e: SyntheticEvent) => void

export const onChangeWrapper =
  (onChangeParent: ValueChangeType<any>, path: string) => (e: SyntheticEvent) => {
    // @ts-ignore
    const { name, value } = dataFromEvent(e)
    onChangeParent({ ...e, target: { ...e.target, name: path + '.' + name, value: value } })
  }

export type ModelMgrProps<P, T = P, E = P, R = P, C = any> = {
  /** Id der Daten zum Laden identifiziert, 'neu' als Markierung für eine Erstellung */
  id?: any
  /** Markierung, dass keine ID verwendet wird */
  noid?: boolean
  /** Name des Service-Parameters für die ID */
  idField?: string
  /** Name des API Felds */
  apiIdField?: string
  /** axios API */
  api: AxiosInstance
  /** Name des Rest-Services */
  rest?: string | null
  /** Erweiterung des Rest-Servicenamen für service-seitige Vorbereitung eines neuen Models */
  restCreate?: string
  /** Erweiterung des Rest-Servicenamen für Laden des Modells */
  restEdit?: string
  /** Erweiterung des Rest-Servicenamen für Speichern des Modells */
  restSave?: string
  restps?: any
  /** Text zum aktuellen Modell für Fehlermeldungen */
  title?: string
  /** Eventname, zu dem automatisch bei Speichern und Löschen ein Event gesendet werden soll. Siehe useObserver */
  eventName?: string
  /**  Daten des Events anpassen, sonst wird das Edit-Modell versendet */
  eventMutator?: (data: E) => any | null
  /** Optionales Feld, um die Daten aus dem Umschlag zu befreien */
  unwrapField?: string | null
  /** Initiales Modell */
  init?: any // TODO: T | (() => T)
  /** Validate prüft das Model auf Fehler, die u.a. das Speichern verhindern */
  validate?: (model: T, lastModel: T, envelope: E) => any
  /** Daten vor dem Speichern anpassen */
  saveMutator?: (model: T, lastPayload: P, Context: C) => R
  /** Daten nach dem Laden anpassen */
  editMutator?: (payload: P, lastModel: T, envelope: E, Context: C) => T
  /** Callback, der nach der Speicherung gerufen wird */
  onSave?: OnSave<T>
  /** Reducer für manipulation des Modells per dispatcher */
  reducer?: any
  /** Ein Kontext für den Reducer */
  context?: any
  /** Ein Value-Decorator, der bei onValueChange Values manipulieren kann */
  valueDecorator?: any
  /** Ein MessageID bei der der Lade/Speicher-Service wiederholt werden muss, da der Server im Hintergrund noch etwas fertigstellen muss. */
  retryMessageId?: string
  /** Einschub, um vor dem Speichern einzugreifen */
  preSaveHook?: ({
    isNew,
    model,
    onContinue
  }: {
    isNew: boolean
    model: T
    onContinue: () => void
  }) => void
  /** asyncState nicht beachten (sonst wird ggf. gewartet) */
  ignoreAsyncTaskState?: boolean
  /** Automatisches Validieren des Modells */
  autoValidate?: boolean
  /** Function für Inputfelder zur automatischen Eventübernahme in das Modell. Dazu müssen dei Inputfelder als Namen einen gültiges Modellfeld nutzen */
  onValueChanged?: (model: T, name: string) => T
  /** ID des Observers, der bei notify genutzt werden soll, um die eigenen Events nicht zu erhalten */
  observerId?: string
  /** Browser-History-Eintrag ersetzen bei Neuanlage (Default: true) */
  doReplaceHistory?: boolean
  /** Spezielle Meldung bei Speiherung statt "Daten wurden gespeichtert" */
  savedMessage?: string
  /** Fehlerstil, flat oder structured */
  errorStyle?: 'flat' | 'structured'
  asyncMode?: 'async-load' // | 'load-save' | 'load-save-post'
}

export type SilentUpdFn<T> = (model: T) => T

export enum SilentUpdTarget {
  RAW = 'RAW',
  EDT = 'EDT'
}

export type SilentUpd<T> = (action: SilentUpdTarget, fn: SilentUpdFn<T>) => void

export type ErrorsType = { [key: string]: any }

export type RemoveParams = {
  srv?: string
  ovrId?: string | number
  params?: any
  addVersion?: boolean
  onRemoved?: (data: any) => void
  onError?: (error: UseCaseStateJson) => void | boolean
}

export interface PostArgs {
  url?: string
  srv?: string
  addVersion?: boolean
  params?: any
  data?: any
  userMessage?: string
  onPost?: (data: any, properties?: any) => boolean
}

export interface ReloadParams {
  notifyReload?: boolean
}

export type ModelMgr<P = any, T = P, E = P> = {
  envelope: E
  raw: P
  model: T

  errors: ErrorsType

  modelState: ModelState

  uiLock: UILockType

  // deprecated...
  wait?: boolean

  isChanged: boolean
  isNew: boolean

  updModel: UpdModelFn<T>
  onValueChange: ValueChangeFn<T>
  dispatch: (action: ModelAction) => void
  save: (callback?: SaveCallBackProps<T>, successMessage?: string, srv?: string) => void // TODO umbauen auf propertyparams
  revert: () => void
  reload: () => void
  reloadEx: (args: ReloadParams) => void
  load: (nextRestps?: any) => void
  silentUpd: SilentUpd<T>
  post: (args: PostArgs) => void
  remove: (args?: RemoveParams) => void
  undo: () => void
  canUndo: boolean
}

type ModelMgrState<P, T, E, R> = {
  state: ModelState
  isNew: boolean
  envelope: E
  raw: E
  org: T
  edt: T
  errors: any
  silent: number
  undoStack: any[]
  undoPos: number
  undoTS: Date
}

type MgrApi<P, T, E, R> = {
  id?: any
  idField?: string
  apiIdField?: string
  ignoreAsyncTaskState?: boolean
  api: AxiosInstance
  rest?: string | null
  restCreate?: string
  restEdit?: string
  restSave?: string
  restps?: any
  title?: string
  unwrapField?: string
  validate?: (model: T, lastModel: T, envelope: E) => ErrorsType
  onSave?: OnSave<T>
  reducer?: any
  context?: any
  valueDecorator?: any
  state: any
  preSaveHook?: ({
    isNew,
    model,
    onContinue
  }: {
    isNew: boolean
    model: T
    onContinue: () => void
  }) => void
  eventName?: string
  observerId?: string
  eventMutator?: (data: E) => any | null
  onValueChanged?: (model: T, name: string) => T
  reload: () => void
  doReplaceHistory: boolean
  mutate4Save: (model: T, lastPayload: P) => R
  mutate4Edit: (payload: P, lastModel: T, envelope: E) => T
  unwrap: (data: E) => P
  isChanged: boolean
  savedMessage?: string
}

/**
 * Model-Manager, erweiterte State-Verwaltung
 *
 * Kern eines Frontend-Anwendungsfalles zur Bearbeitung von Daten.
 * Umfasst alle nötigen Abläufe für Laden, Speichern, Löschen, validieren und reagieren auf Service-Fehler.
 * Unterstützt Warten auf asynchrone Server-Vorgänge und Warten auf Service-Bereitschaftsmeldung via Retry vias MessageID.
 * Bietet einfache Methoden zum Ändern von Modelen via UI-Events (name/value), bei dem der Name ein kompletter Pfad im Modellbaum sein kann, über Referenzen und durch Collections.
 *
 * @template P Typ der geladenen Daten
 * @template T Typ des Edit-Model
 * @template E Typ der Hülle (Envelope), das die Daten nach dem Laden <P> umgibt, Default: <P>
 * @template R Typ für die Speicherung, Default: <P>
 * @returns
 */
export const useModelMgr = <P = any, T = P, E = P, R = P>({
  id,
  noid,
  idField = 'id',
  apiIdField = idField,
  api,
  rest,
  restCreate = 'create',
  restEdit = 'edit',
  restSave = 'save',
  asyncMode,
  restps = empty,
  title = 'Daten',
  eventName,
  eventMutator,
  unwrapField,
  init = empty as T,
  validate,
  saveMutator,
  editMutator,
  onSave,
  reducer,
  context,
  valueDecorator,
  retryMessageId,
  preSaveHook,
  ignoreAsyncTaskState,
  autoValidate,
  onValueChanged,
  observerId,
  doReplaceHistory = true,
  savedMessage,
  errorStyle = 'flat'
}: ModelMgrProps<P, T, E, R>): ModelMgr<P, T, E> => {
  const apiRef = useRef<MgrApi<P, T, E, R>>()
  const { enqueState, enqueError } = useSnackbarEx()
  const [apiCall] = useApiCaller(api)
  const [asyncPoller] = useAsyncTaskPoller()

  const waitTimerRef = useRef<any>()
  const retryTimerRef = useRef<any>()
  const validateTimerRef = useRef<any>()

  const appSwitchCtx = useContext(AppSwitchCtx)
  const registerDirtyHook = appSwitchCtx?.registerDirtyHook
  const replaceHistory = appSwitchCtx?.replaceHistory

  const appRouteCtx = useContext(AppRouteCtx)
  const navAction = appRouteCtx?.action
  const isVisible = appRouteCtx?.visible

  const mayInit = useCallback(() => {
    // @ts-ignore
    const ri = typeof init === 'function' ? init() : init
    return {
      state: ModelState.init,
      isNew: id === 'neu' /* || id == null nicht, da init trigger... */,
      envelope: {},
      raw: ri,
      org: ri,
      edt: ri,
      errors: empty,
      silent: 0,
      undoStack: new Array(100).fill(null),
      undoPos: 0,
      undoTS: Date.now()
    }
  }, [id, init])

  const adapter = useCallback((state, action: any) => {
    const withUndo = (next: any) => {
      if (next == null) {
        return next
      }
      let ts = Date.now()
      let idx = next.undoPos
      if (ts - next.undoTS > 500) {
        idx = idx + 1
        idx = idx >= next.undoStack.length ? 0 : idx
        next.undoStack[idx] = state.edt
        next.undoPos = idx
      }
      next.undoTS = ts
      return next
    }
    switch (action.type) {
      case 'setState': {
        const rs = {
          ...state,
          undoStack: new Array<T>(100).fill(null),
          undoTS: Date.now(),
          undoPos: 0
        }
        const next = typeof action.data === 'function' ? action.data(state) : action.data
        return isEqual(next, rs) ? rs : next
      }
      case 'updState': {
        const next = { ...state, ...action.data }
        return isEqual(next, state) ? state : next
      }
      case 'setUiLock': {
        const uiLock = typeof action.data === 'function' ? action.data(state.uiLock) : action.data
        return isEqual(uiLock, state.uiLock) ? state : { ...state, uiLock }
      }
      case 'updModel': {
        const edt =
          typeof action.data === 'function'
            ? action.data(state.edt)
            : { ...state.edt, ...action.data }
        return isEqual(edt, state.edt) ? state : withUndo({ ...state, edt })
      }
      case 'revert': {
        const edt = state.org
        return isEqual(edt, state.edt) ? state : withUndo({ ...state, edt })
      }
      case 'onChange': {
        const { name, value } = action.data
        let edt = updateObjectField(state.edt, name, value)
        if (apiRef.current.onValueChanged) {
          edt = apiRef.current.onValueChanged(edt, name)
        }
        return isEqual(edt, state.edt) ? state : withUndo({ ...state, edt })
      }
      case 'silentUpd': {
        return { ...state, silent: state.silent++ }
      }
      case 'onEdit': {
        const mgr = apiRef.current
        if (mgr.reducer) {
          const edt = mgr.reducer(state.edt, action.data)
          return isEqual(edt, state.edt) ? state : withUndo({ ...state, edt })
        }
        errorLog('No model reducer!', action.data)
        return state
      }
      case 'undo': {
        const edt = state.undoStack[state.undoPos]
        if (edt != null) {
          state.undoStack[state.undoPos] = null
          return {
            ...state,
            edt,
            undoPos: state.undoPos > 0 ? state.undoPos - 1 : state.undoStack.length - 1
          }
        }
        return state
      }
      default: {
        errorLog('Action not implemented!', action)
        return state
      }
    }
  }, [])

  const [state, dispatchInt] = useReducer(adapter, initialState, mayInit)

  const updState = useCallback((data) => {
    dispatchInt({ type: 'updState', data })
  }, [])

  const setState = useCallback((data) => {
    dispatchInt({ type: 'setState', data })
  }, [])

  const setUiLock = useCallback((data) => {
    dispatchInt({ type: 'setUiLock', data })
  }, [])

  const undo = useCallback(() => {
    dispatchInt({ type: 'undo' })
  }, [])

  // const observerId = useObserver(eventName, (event) => {
  //   const eventId = event.data?.id
  //   if (eventId != null && eventId == id) {
  //     enqueError('Die Daten wurden außerhalb des Editors geändert!')
  //     mgrRef.current?.reload()
  //   }
  // })

  const revalidate = useCallback(() => {
    const mgr = apiRef.current
    if (!mgr) {
      return
    }
    const latest = mgr.state
    if (autoValidate || (latest.errors && Object.keys(latest.errors).length > 0)) {
      restartTimer(
        validateTimerRef,
        () => {
          const mgr = apiRef.current
          if (!mgr) {
            return
          }
          const latest = mgr.state
          const errors = mgr.validate?.(latest.edt, latest.org, latest.envelope) || empty
          updState((old) => ({ ...old, errors: { ...errors, ...old.serverErrors } }))
        },
        1000
      )
    }
  }, [autoValidate, updState])

  const dispatch = useCallback(
    (action: ModelAction): void => {
      const mgr = apiRef.current
      if (mgr == null) {
        return
      }
      let syncActionDone = false
      let asyncActions = []
      const asyncDispatch = (aa) => {
        asyncActions.push(aa)
        if (syncActionDone) {
          const copy = [...asyncActions]
          asyncActions = []
          copy.forEach((a) => dispatch(a))
        }
      }
      dispatchInt({
        type: 'onEdit',
        data: action,
        dispatch: asyncDispatch,
        api: mgr.api,
        context: mgr.context
      })
      syncActionDone = true
      revalidate()
    },
    [revalidate]
  )

  const updModel = useCallback(
    (param: UpdModelType<T>) => {
      dispatchInt({ type: 'updModel', data: param })
      revalidate()
    },
    [revalidate]
  )

  const initModel = useCallback(
    (mgr, envelope: E, reload: boolean): boolean => {
      const payload = mgr.unwrap(envelope)
      if (!payload) {
        return false
      }
      const org = mgr.state.org
      const tampered = mgr.mutate4Edit(payload, org, envelope)
      updState({
        envelope,
        raw: payload,
        org: tampered,
        edt: tampered,
        state: ModelState.ready,
        loaded: reload,
        uiLock: {},
        undoPos: 0,
        undoTS: Date.now(),
        undoStack: new Array(100).fill(null)
      })

      return true
    },
    [updState]
  )

  const onValueChange = useCallback(
    (e) => {
      const data = dataFromEvent(e, apiRef.current?.valueDecorator)
      if (data != null && data.name != null) {
        dispatchInt({ type: 'onChange', data })
        revalidate()
      } else {
        errorLog('Invalid parameter for onValueChange', data, e)
      }
    },
    [revalidate]
  )

  const isChanged = useMemo(() => {
    return !isEqual(state.org, state.edt)
  }, [state.org, state.edt])

  const sendNotify = useCallback((action: ModelMgrEventAction, data: any) => {
    const mgr = apiRef.current
    if (mgr.eventName) {
      notifyObservers({
        name: mgr.eventName,
        origin: mgr.observerId,
        action,
        data: mgr.eventMutator ? mgr.eventMutator(data) : data
      })
    }
  }, [])

  const startWaitTimer = useCallback(
    (waitMessage: string) => {
      restartTimer(
        waitTimerRef,
        () => {
          setState((old: any) => ({
            ...old,
            uiLock: {
              ...old.uiLock,
              waitMessage
            }
          }))
        },
        6000
      )
    },
    [setState]
  )

  const handleRetry = useCallback(
    (error: UseCaseStateJson, title: string, retry: () => void) => {
      if (apiRef.current.state.retryCount >= 6) {
        updState({
          uiLock: {
            mode: UILockMode.ERROR,
            title,
            error,
            retryCallback: () => {
              setState((old) => ({
                ...old,
                retryCount: 0,
                uiLock: { ...old.uiLock, mode: UILockMode.RETRY }
              }))
              retry()
            }
          }
        })
        return true
      }

      restartTimer(
        retryTimerRef,
        () => {
          setState((old: any) => ({
            ...old,
            retryCount: old.retryCount == null ? 1 : old.retryCount + 1,
            uiLock: {
              ...old.uiLock,
              title,
              error,
              mode: UILockMode.RETRY,
              cancelCallback: () => {
                setState((old) => ({
                  ...old,
                  retryCount: 1000000,
                  uiLock: { ...old.uiLock, cancelled: true }
                }))
              }
            }
          }))
          retry()
        },
        3000
      )
    },
    [setState, updState]
  )

  const save = useCallback(
    (callback?: SaveCallBackProps<T>, successMessage?: string, srv?: string) => {
      const mgr = apiRef.current
      const latest = mgr.state
      if (!mgr.isChanged && !latest.isNew) {
        if (callback && callback.catch) {
          callback.catch(new Error('Keine Änderungen zu speichern!'))
        }
        return
      }

      if (mgr.validate) {
        const errors = mgr.validate(latest.edt, latest.org, latest.envelope)
        if (!isEmptyEx(errors)) {
          updState({ errors })

          const unpack = (obj: any): KeyValuePair[] => {
            return fieldsToArray(obj)
              .map((s) => (isObject(s.value) ? unpack(s.value) : s))
              .flat()
          }

          const error = {
            mainMessage: {
              message: 'Es sind Fehler im Formular!',
              severity: MessageSeverity.ERR
            },
            messages: unpack(errors)
              .map((s) => s.value)
              .filter(isString)
              .map((s) => ({
                message: s,
                severity: MessageSeverity.ERR
              }))
          } as UseCaseStateJson
          enqueState(error)
          if (callback && callback.catch) {
            callback.catch(new Error('Es sind noch Fehler im Formular!'))
          }
          return
        }
      }

      const saveFnc = () => {
        const tampered = mgr.mutate4Save(latest.edt, latest.raw)

        const rs = `${mgr.rest}/${srv || mgr.restSave}`

        startWaitTimer('Speichern läuft...')

        setState((old: any) => ({
          ...old,
          state: ModelState.saving,
          uiLock: {
            ...old.uiLock,
            waitMessage: null,
            mode: old.uiLock?.mode || UILockMode.WAIT
          }
        }))

        apiCall<HasUseCaseState>({
          method: 'POST',
          rest: rs,
          data: tampered,

          onSuccessMsg:
            successMessage ||
            mgr.savedMessage ||
            (latest.isNew
              ? `${mgr.title} erfolgreich erstellt`
              : `${mgr.title} erfolgreich gespeichert`),

          onSuccess: (data) => {
            clearTimer(waitTimerRef)

            const next = mgr.unwrap(data as E)
            let envelope = data
            let raw = next
            const nextTampered = next && mgr.mutate4Edit(next, latest.edt, data as E)
            if (unwrapField && envelope?.state?.mainMessage?.messageId?.id === MessageID.ID_DELTA) {
              envelope = {
                ...latest.envelope,
                ...data,
                [unwrapField]: nextTampered
              }
              raw = { ...latest.raw, ...nextTampered }
            }

            if (
              !mgr.ignoreAsyncTaskState &&
              isAsyncTaskStatusActive(data.state?.asyncTask?.status)
            ) {
              asyncPoller({
                apiCall,
                setUiLock,
                asyncTask: data.state?.asyncTask,
                title: 'Die Verarbeitung wird abgeschlossen, bitte warten...',
                onReady: (at) => {
                  if (at?.error) {
                    updState({
                      state: ModelState.ready,
                      uiLock: { mode: null }
                      // errors: empty
                    })
                  } else {
                    if (onSave) {
                      if (!onSave(nextTampered, at?.properties)) {
                        apiRef.current.reload()
                      }
                    } else if (callback && callback.then) {
                      if (!callback.then(nextTampered, at?.properties)) {
                        apiRef.current.reload()
                      }
                    } else {
                      apiRef.current.reload()
                    }
                  }
                }
              })
              return true
            }

            if (next) {
              if (latest.isNew) {
                updState({
                  state: ModelState.ready,
                  envelope,
                  raw,
                  edt: nextTampered,
                  org: nextTampered,
                  isNew: false,
                  uiLock: { mode: null },
                  errors: empty
                })
                if (mgr.doReplaceHistory && replaceHistory && nextTampered[mgr.idField]) {
                  replaceHistory((loc: string) => loc.replace(/neu.*/, nextTampered[mgr.idField]))
                }
              } else {
                updState({
                  state: ModelState.ready,
                  envelope,
                  raw,
                  edt: nextTampered,
                  org: nextTampered,
                  isNew: false,
                  uiLock: { mode: null },
                  errors: empty
                })
              }
              if (onSave) {
                onSave(nextTampered, null)
              }
              if (callback && callback.then) {
                callback.then(nextTampered, null)
              }
              sendNotify(ModelMgrEventAction.UPDATED, nextTampered)
            } else {
              updState({
                state: ModelState.error,
                uiLock: {
                  mode: UILockMode.ERROR,
                  error: {
                    mainMessage: {
                      message: 'Der Service lieferte keine Daten',
                      severity: MessageSeverity.ERR
                    }
                  } as UseCaseStateJson
                }
              })
              if (callback && callback.catch) {
                callback.catch(new Error('Der Service hat keine Daten geliefert'))
              }
            }
          },

          onErrorMsg: `Speichern von ${mgr.title} gescheitert.`,

          onError: (error, response) => {
            clearTimer(waitTimerRef)

            let messages = [] as MessageJson[]
            if (error?.mainMessage) {
              messages = [error.mainMessage]
            }
            if (error?.messages?.length > 0) {
              messages = messages.concat(error?.messages)
            }
            if (messages.length === 0) {
              messages = [
                {
                  severity: MessageSeverity.FATAL,
                  message: 'Unbehandelter Servicefehler:' + response.statusText
                }
              ]
            }

            const errors = messageToErrors(messages, errorStyle)

            if (retryMessageId && error?.mainMessage?.messageId?.id === retryMessageId) {
              updState({ state: ModelState.ready, errors: errors, serverErrors: errors })
              handleRetry(
                error,
                `${latest.isNew ? 'Anlegen' : 'Speichern'} wird noch verhindert...`,
                () => saveFnc()
              )
              return true
            }

            updState({ state: ModelState.ready, errors: errors, uiLock: {} })

            if (callback && callback.catch) {
              callback.catch(error)
            }

            return false
          }
        })
      }

      if (mgr.preSaveHook) {
        mgr.preSaveHook({ model: latest.edt, isNew: latest.isNew, onContinue: saveFnc })
      } else {
        saveFnc()
      }
    },
    [
      updState,
      enqueState,
      startWaitTimer,
      setState,
      apiCall,
      unwrapField,
      asyncPoller,
      setUiLock,
      onSave,
      sendNotify,
      replaceHistory,
      retryMessageId,
      handleRetry
    ]
  )

  const revert = useCallback(() => {
    dispatchInt({ type: 'revert' })
  }, [])

  const load = useCallback(
    (nextRestps: any) => {
      const mgr = apiRef.current
      if (mgr.rest != null) {
        const latest = mgr.state
        const rps = nextRestps ?? mgr.restps
        const name = latest.isNew ? mgr.restCreate : mgr.restEdit
        if (name == null) {
          updState({ state: ModelState.ready })
          return
        }
        const rs = name.length ? `${mgr.rest}/${name}` : mgr.rest
        const ps = {
          ...rps
        }

        let lastVers = null
        if (!latest.isNew && !noid) {
          ps[mgr.apiIdField] = mgr.id
          lastVers = latest.edt?.version
        }

        startWaitTimer('Daten werden geladen...')

        setState((old: any) => ({
          ...old,
          state: ModelState.loading,
          uiLock: {
            ...old.uiLock,
            waitMessage: null,
            mode: old.uiLock?.mode || UILockMode.WAIT
          }
        }))

        const headers: any =
          lastVers != null
            ? {
                'If-None-Match': lastVers
              }
            : undefined

        const onSuccess = (data) => {
          clearTimer(waitTimerRef)
          if (isAsyncTaskActive(data.state?.asyncTask)) {
            asyncPoller({
              apiCall,
              setUiLock,
              asyncTask: data.state?.asyncTask,
              title: null,
              onReady: () => {
                // const next = mgr.unwrap(data)
                // const nextTampered = next && mgr.mutate4Edit(next, latest.edt, data)
                apiRef.current.reload()
              }
            })
            return true
          }
          if (initModel(mgr, data, true)) {
            updState({
              uiLock: {}
            })
          } else {
            errorLog(`Service ${rs} lieferte keine Daten title=${mgr.title} id=${mgr.id}`)
            setState((old: any) => ({
              state: ModelState.error,
              uiLock: {
                mode: UILockMode.ERROR,
                error: {
                  mainMessage: {
                    message: 'Der Service lieferte keine Daten',
                    severity: MessageSeverity.ERR
                  }
                } as UseCaseStateJson
              }
            }))
          }
        }

        const onErrorMsg = latest.isNew
          ? `Neue ${mgr.title} konnte nicht vorbereitet werden.`
          : `${mgr.title} mit ID "${mgr.id}" konnte nicht geladen werden.`

        const onError = (error, response) => {
          clearTimer(waitTimerRef)
          if (response?.status === 304) {
            updState({
              state: ModelState.ready,
              uiLock: {}
            })
            return true
          }

          if (retryMessageId && error?.mainMessage?.messageId?.id === retryMessageId) {
            handleRetry(error, 'Der Vorgang wird noch verhindert', () => apiRef.current.reload())
            return true
          }

          updState({
            state: ModelState.error,
            uiLock: {
              mode: UILockMode.ERROR,
              error,
              reload: () => {
                apiRef.current.reload()
              }
            }
          })
          return false
        }

        if (asyncMode === 'async-load') {
          apiCall({
            method: 'GET',
            rest: rs + '/async-start',
            params: ps,
            headers,
            onErrorMsg,
            onError,
            onSuccess: (data: AsyncTaskResponseJson) => {
              asyncPoller({
                apiCall,
                asyncTask: data.state.asyncTask,
                title: null,
                setUiLock,
                onReady: (asyncTask) => {
                  if (asyncTask.status === 'FINISHED') {
                    apiCall({
                      method: 'GET',
                      rest: rs + '/async-get',
                      params: { key: data.key },
                      onSuccess,
                      onErrorMsg,
                      onError
                    })
                  }
                }
              })
            }
          })
        } else {
          apiCall<any>({
            method: 'GET',
            rest: rs,
            params: ps,
            headers,
            onSuccess,
            onErrorMsg,
            onError
          })
        }
      } else {
        updState({
          state: ModelState.ready
        })
      }
    },
    [
      noid,
      startWaitTimer,
      setState,
      apiCall,
      updState,
      initModel,
      asyncPoller,
      setUiLock,
      retryMessageId,
      handleRetry
    ]
  )

  const reload = useCallback((nextRestps?: any) => load(null), [load])

  const reloadEx = useCallback(
    ({ notifyReload }: ReloadParams) => {
      load(null)
      if (notifyReload) {
        enqueError('Die Daten wurden außerhalb des Editors geändert und werden neu geladen!')
      }
    },
    [enqueError, load]
  )

  const post = useCallback(
    ({ url, srv, params, data, userMessage, onPost, addVersion }: PostArgs) => {
      startWaitTimer('Aktion läuft...')
      updState({
        state: ModelState.posting,
        uiLock: {
          mode: UILockMode.WAIT
        }
      })

      const mgr = apiRef.current
      const rps = mgr.restps

      const restparams = params != null ? params : { ...rps, id: mgr.id }
      if (addVersion) {
        restparams.version = mgr.state?.edt?.version
      }

      apiCall<any>({
        method: 'POST',
        rest: url || `${mgr.rest}/${srv}`,
        params: restparams,
        data,
        onSuccess: (data) => {
          clearTimer(waitTimerRef)
          if (isAsyncTaskActive(data.state?.asyncTask)) {
            const mgr = apiRef.current
            const latest = mgr.state
            const next = mgr.unwrap(data)
            const nextTampered = next && mgr.mutate4Edit(next, latest.edt, data)
            asyncPoller({
              apiCall,
              setUiLock,
              asyncTask: data.state?.asyncTask,
              title: 'Die Verarbeitung wird abgeschlossen, bitte warten...',
              onReady: (at) => {
                if (at.error) {
                  setUiLock({})
                  apiRef.current.reload()
                } else {
                  if (onPost) {
                    if (!onPost(nextTampered, at?.properties)) {
                      apiRef.current.reload()
                    }
                    // } else if (callback && callback.then) {
                    //   if (!callback.then(nextTampered, at?.properties)) {
                    //     mgrRef.current.reload()
                    //   }
                  } else {
                    apiRef.current.reload()
                  }
                }
              }
            })
            return
          }

          initModel(mgr, data, false)
          updState({ state: ModelState.ready, uiLock: { mode: null } })
          sendNotify(ModelMgrEventAction.UPDATED, data)

          if (onPost) {
            onPost(data)
          }
        },
        onErrorMsg: `Aktion ${userMessage || ''} für ${mgr.title} mit ID ${mgr.id} gescheitert.`,
        onError: () => {
          clearTimer(waitTimerRef)
          updState({ state: ModelState.ready, uiLock: {} })
        }
      })
    },
    [startWaitTimer, updState, apiCall, initModel, sendNotify, asyncPoller, setUiLock]
  )

  const remove = useCallback(
    ({ onRemoved, srv, ovrId, params, onError, addVersion }: RemoveParams) => {
      const mgr = apiRef.current
      const rps = mgr.restps
      const restparams = params != null ? params : { ...rps, id: ovrId != null ? ovrId : mgr.id }
      if (addVersion) {
        restparams.version = mgr.state?.edt?.version
      }

      updState({
        state: ModelState.posting,
        uiLock: {
          mode: UILockMode.WAIT
        }
      })

      apiCall({
        method: 'POST',
        rest: `${mgr.rest}/${srv || 'delete'}`,
        params: restparams,
        onSuccess: (data) => {
          // initModel kann zur Zeit nicht erkennen, ob gelöscht wurde oder nicht, führt ggf. zu Fehlern (Bsp. EKPreslisten)
          // const next = mgr.unwrap(data)
          // if (next != null) {
          //   initModel(mgr, next)
          // }
          updState({ state: ModelState.removed, uiLock: { mode: null } })
          if (onRemoved) {
            onRemoved(data)
          }
          sendNotify(ModelMgrEventAction.REMOVED, data)
        },
        onErrorMsg: `Löschen von ${mgr.title} mit ID ${mgr.id} gescheitert.`,
        onError: (error) => {
          updState({ state: ModelState.ready, uiLock: { mode: null } })
          if (onError) {
            return onError(error)
          }
        }
      })
      updState({ state: ModelState.posting })
    },
    [apiCall, sendNotify, updState]
  )

  useImperativeHandle(
    apiRef,
    () => ({
      id,
      idField,
      apiIdField,
      ignoreAsyncTaskState,
      api,
      rest,
      restCreate,
      restEdit,
      restSave,
      restps,
      title,
      unwrapField,
      validate,
      onSave,
      reducer,
      context,
      valueDecorator,
      state,
      isChanged,
      preSaveHook,
      eventName,
      observerId,
      eventMutator,
      onValueChanged,
      reload,
      savedMessage,
      doReplaceHistory,
      mutate4Save: (model: T, lastPayload: P): R => {
        if (saveMutator) {
          return saveMutator(model, lastPayload, context)
        }
        return model as any
      },
      mutate4Edit: (payload: P, lastModel: T, envelope: E): T => {
        if (editMutator) {
          return editMutator(payload, lastModel, envelope, context)
        }
        return payload as any
      },
      unwrap: (data: E): P => (unwrapField ? data[unwrapField] : data)
    }),
    [
      id,
      idField,
      ignoreAsyncTaskState,
      api,
      rest,
      restCreate,
      restEdit,
      restSave,
      restps,
      title,
      unwrapField,
      validate,
      onSave,
      reducer,
      context,
      valueDecorator,
      state,
      isChanged,
      preSaveHook,
      eventName,
      observerId,
      eventMutator,
      onValueChanged,
      reload,
      savedMessage,
      doReplaceHistory,
      saveMutator,
      editMutator
    ]
  )

  const last = useRef<any>()
  useEffect(() => {
    const key = { id, restps }
    if (
      (id != null || noid) &&
      ((state.state === ModelState.init && last.current == null) || !isEqual(last.current, key))
    ) {
      last.current = key
      reload()
    }
  }, [reload, state.state, id, restps, noid])

  const lastVisibleRef = useRef(isVisible)
  useEffect(() => {
    if (isVisible !== lastVisibleRef.current) {
      lastVisibleRef.current = isVisible
      if (isVisible && !isChanged && navAction === 'PUSH') {
        debugLog('ModelMgr.reloadOnReshow')
        reload()
      }
    }
  }, [isChanged, isVisible, navAction, reload])

  useEffect(() => {
    if (registerDirtyHook) {
      return registerDirtyHook(isChanged)
    }
    return undefined
  }, [isChanged, registerDirtyHook])

  const silentUpd = (action: SilentUpdTarget, silentUpdFn: SilentUpdFn<T>) => {
    if (action === SilentUpdTarget.RAW) {
      state.raw = silentUpdFn(state.raw)
    } else if (action === SilentUpdTarget.EDT) {
      const edt = silentUpdFn(state.edt)
      state.edt = edt
      state.org = edt
    }

    dispatchInt({ type: 'silentUpd' })
  }
  debugLog('ModelMgr', state)

  return {
    envelope: state.envelope || {},
    raw: state.raw || {},
    model: state.edt || {},
    errors: state.errors || {},

    modelState: state.state,

    uiLock: state.uiLock,
    wait: state.uiLock?.mode === UILockMode.WAIT,

    isChanged,
    isNew: state.isNew,

    updModel,
    onValueChange,
    dispatch,
    save,
    revert,
    load,
    reload,
    reloadEx,
    post,
    remove,
    silentUpd,
    undo,
    canUndo: state.undoStack && state.undoStack[state.undoPos] != null
  }
}
