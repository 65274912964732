import { SparteDisplayJson } from '@one/typings/apiTypings'
import { AutocompleteEx } from '@utils/ui/fields/AutocompleteEx'
import { ChangeFn } from '@utils/modelmgr'
import { useDataCache } from '@utils/datacache'
import { api } from '@one/api'

export interface SparteFieldProps {
  value: number | SparteDisplayJson
  name: string
  onChange?: ChangeFn
  fullWidth?: boolean
  required?: boolean
  error?: any
  idMode?: boolean
}

export const SpartenField = ({
  value,
  name,
  onChange,
  fullWidth,
  required,
  error,
  idMode
}: SparteFieldProps) => {
  const spartenCache = useDataCache<SparteDisplayJson>({
    api,
    datakey: 'sparten',
    rest: '/artikel/sparten',
    method: 'GET',
    params: { auchLeere: true },
    field: 'data'
  })
  return (
    <AutocompleteEx<SparteDisplayJson>
      label="Sparte"
      required={required}
      value={value}
      name={name}
      onChange={onChange}
      fullWidth={fullWidth}
      options={spartenCache.data}
      optionLabel={(opt) => opt.nr + ' ' + (opt.bezeichnung || '')}
      optionValue={idMode ? (opt) => opt.id : (opt) => opt}
      isOptionEqualToValue={
        idMode ? (opt, val) => opt.id === val : (opt, val) => opt.id === val?.id
      }
      error={error}
    />
  )
}
