import { AppPaths } from '@one/AppPaths'
import { EventNames } from '@one/EventNames'
import { api } from '@one/api'
import { EigenlistungsLieferantBearbeitenJson, SeLieferantJson } from '@one/typings/apiTypings'
import { useModelMgr } from '@utils/modelmgr'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { DeleteButton } from '@utils/ui/Buttons/DeleteButton'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { CardEx } from '@utils/ui/CardEx'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { IndexPanel, IndexPanelItem } from '@utils/ui/IndexPanel'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { useContext } from 'react'
import { LieferantAdresseMemo } from './LieferantAdresse'
import { LieferantBezeichnungMemo } from './LieferantBezeichnung'
import { LieferantSperreMemo } from './LieferantSperre'
import { LieferantenLieferbedingungMemo } from './LieferantenLieferbedingung'

export const EigenlistungsLieferantPflegeView = () => {
  const { id } = useContext(RouteContext) as any

  const { model, isNew, isChanged, errors, save, reload, uiLock, remove, onValueChange, updModel } =
    useModelMgr<EigenlistungsLieferantBearbeitenJson, SeLieferantJson>({
      id,
      api,
      title: 'Eigenlistungslieferant',
      rest: 'lieferant/eigenlistung',
      unwrapField: 'seLieferant',
      eventName: EventNames.EIGENLISTUNGSLIEFERANT
    })

  const content = [
    {
      id: 'bezeichnung',
      label: 'Lieferant',
      exclude: model?.dzLieferant == null,
      body: () => (
        <LieferantBezeichnungMemo
          dzLieferant={model.dzLieferant}
          onValueChange={onValueChange}
          errors={errors}
          seLieferantId={model?.id}
        />
      )
    },
    {
      id: 'hauptadresse',
      label: 'Hauptadresse',
      exclude: model?.dzLieferant == null,
      body: () => (
        <LieferantAdresseMemo
          adresse={model?.dzLieferant.hauptAdresse}
          onValueChange={onValueChange}
          title="Hauptadresse"
          name="dzLieferant.hauptAdresse"
          hideName
        />
      )
    },
    {
      id: 'abholadresse',
      label: 'Abholadresse',
      exclude: model?.dzLieferant == null,
      body: () => (
        <LieferantAdresseMemo
          adresse={model?.dzLieferant.abholAdresse}
          onValueChange={onValueChange}
          title="Abholadresse"
          name="dzLieferant.abholAdresse"
          hideName
          addAdresse={() => {
            updModel({ dzLieferant: { ...model.dzLieferant, abholAdresse: {} } })
          }}
          remAdresse={() => {
            updModel({ dzLieferant: { ...model.dzLieferant, abholAdresse: null } })
          }}
        />
      )
    },

    {
      id: 'sperren',
      label: 'Sperren',
      exclude: model?.dzLieferant == null,
      body: () => (
        <LieferantSperreMemo
          dzLieferant={model?.dzLieferant}
          onValueChange={onValueChange}
          errors={errors}
        />
      )
    },
    {
      id: 'lieferbedingungen',
      label: 'Lieferbedingungen',
      exclude: model?.dzLieferant == null,
      body: () => (
        <LieferantenLieferbedingungMemo
          dzLieferant={model?.dzLieferant}
          onValueChange={onValueChange}
          errors={errors}
        />
      )
    }
  ] as IndexPanelItem[]

  return (
    <StatePlane uiLock={uiLock}>
      <Frame space>
        <FrameRow>
          <CardEx
            title={
              isNew
                ? 'Neuer Eigenlistungslieferant'
                : `Eigenlistungslieferant ${model?.dzLieferant?.nummer} ${model?.dzLieferant?.hauptAdresse?.name1}`
            }
            backLink
            overviewLink={AppPaths.LieferantUebersicht}
            contentStyle={{ paddingBottom: 0 }}
          />
        </FrameRow>
        <FrameBody>
          <IndexPanel content={content} />
        </FrameBody>
        <FrameRow>
          <ButtonRow>
            <DeleteButton isNew={isNew} remove={remove} addVersion />
            <SaveButton isNew={isNew} isChanged={isChanged} onClickVoid={save} />
            <ReloadButton isNew={isNew} isChanged={isChanged} onClickVoid={reload} />
          </ButtonRow>
        </FrameRow>
      </Frame>
    </StatePlane>
  )
}
