import { Divider, Grid, Paper } from '@mui/material'
import { pink } from '@mui/material/colors'
import { AppPaths } from '@one/AppPaths'
import { EventNames } from '@one/EventNames'
import { api } from '@one/api'
import { EkPreisVorgangErstellenDialog } from '@one/components/ArtikelAbo/EkPreisVorgangErstellenDialog'
import { useArtikelBetriebstypCache } from '@one/datacaches/useArtikelBetriebsTypeCache'
import {
  useWarengruppeUndSparteListeGlobal,
  useWarengruppeUndSparteListeSelektion
} from '@one/datacaches/useWarengruppeUndSparteListe'
import { HkmEnum } from '@one/enums/HkmEnum'
import {
  ArtikelSelektionBearbeitenJson,
  ArtikelSelektionJson,
  ArtikelSelektionType,
  BetriebstypJson
} from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { useModelMgr } from '@utils/modelmgr'
import { notifyObservers } from '@utils/observer'
import { useQuery } from '@utils/routing'
import { AppContext } from '@utils/ui/App/AppContext'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { UndoButton } from '@utils/ui/Buttons/UndoButton'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { ThemeContext } from '@utils/ui/Theme'
import { TooltipWrapper } from '@utils/ui/TooltipWrapper'
import { SelectField } from '@utils/ui/fields/SelectField'
import { Switch } from '@utils/ui/fields/Switch'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { SnackbarType, useSnackbarEx } from '@utils/ui/snackbarex'
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { ArtikelAboSelektionArtikelTable } from './ArtikelSelektionArtikelTable'
import { ArtikelAboSelektionForm } from './ArtikelSelektionForm'
import { ArtikelAbonniertStatusEx, ArtikelSelektionEdit } from './model/ArtikelSelektionTypes'
import { ArtikelAboSelektionUsecase } from './model/ArtikelSelektionUsecase'

export const ArtikelAboSelektionView = () => {
  const { id } = useContext(RouteContext) as any
  const [{ artikelBT, filter }, setQuery] = useQuery()
  const { items } = useEnums()
  const { isWithEkPreis, isPIMModel } = useContext(AppContext)
  const navigateOnSaveRef = useRef(false)
  const { enqueMsg } = useSnackbarEx()
  const { askToConfirm } = useMessageDialog()
  const navigate = useNavigate()
  const { darkMode } = useContext(ThemeContext)

  const [DlgAnker, showDialog] = useDialogAnker()

  const { get: getBT } = useArtikelBetriebstypCache()

  const onSave = useCallback(
    (model: ArtikelSelektionEdit, asyncProperties: any) => {
      const artikelListeRef = asyncProperties['VORG_REF']
      const vorgangFailure = asyncProperties['VORG_NICHTERZEUGT']
      if (vorgangFailure) {
        enqueMsg(
          'Ein Preisvorgang wurde nicht erzeugt, da kein Artikel dafür ermittelt wurde.',
          SnackbarType.warning
        )
      } else if (artikelListeRef) {
        if (navigateOnSaveRef.current) {
          navigateOnSaveRef.current = false
          navigate(AppPaths.EkPreisAnlageFn({ artikelListeRef }))
        }
        notifyObservers({ name: EventNames.DASHBOARD })
      }
      return false
    },
    [enqueMsg, navigate]
  )

  const {
    model,
    envelope,
    isNew,
    isChanged,
    errors,
    uiLock,
    wait,
    onValueChange,
    save,
    reload,
    dispatch,
    silentUpd,
    undo,
    canUndo
  } = useModelMgr<ArtikelSelektionJson, ArtikelSelektionEdit, ArtikelSelektionBearbeitenJson>({
    id,
    api,
    asyncMode: 'async-load',
    title: 'Artikelselektion',
    unwrapField: 'selektion',
    rest: 'artikelselektion',
    restps: { filter },
    init: { ergebnisse: [] },
    reducer: ArtikelAboSelektionUsecase.reducer,
    validate: ArtikelAboSelektionUsecase.validate,
    saveMutator: ArtikelAboSelektionUsecase.saveMutator,
    editMutator: ArtikelAboSelektionUsecase.editMutator,
    onSave,
    eventName: EventNames.ARTIKELABOSELECTION
  })

  const [warengruppen, sparten] = useWarengruppeUndSparteListeSelektion(id)

  const [warengruppenGlobal, spartenGlobal] = useWarengruppeUndSparteListeGlobal()

  const handleSave = () => {
    const doHandleSave = () => {
      if (isWithEkPreis && !model.preisaenderungenGetrennt && model.changesMap.size > 0) {
        showDialog((open, onClose) => (
          <EkPreisVorgangErstellenDialog
            open={open}
            onClose={onClose}
            onNavigate={() => {
              navigateOnSaveRef.current = true
            }}
            onCheck={(checked: boolean) => {
              dispatch({ type: ArtikelAboSelektionUsecase.SETERZEUGEPREISVORGANG, value: checked })
            }}
            onSave={save}
            hatPreisaenderungen={false}
          />
        ))
      } else {
        save()
      }
    }
    if (model.changesMap.size) {
      const hatSparten = sparten?.length > 0 || spartenGlobal?.length > 0
      const needsDefaults =
        model.wgSparteAuswahlManuell &&
        model.ergebnisse.find((e) => {
          const chg = model.changesMap.get(e.id)
          if (
            !e.erpVorhanden && // nur wenn nicht im ERP vorhanden
            e.statusEx !== ArtikelAbonniertStatusEx.EINGESCHL &&
            chg?.statusEx === ArtikelAbonniertStatusEx.EINGESCHL
          ) {
            if (
              (hatSparten && chg.seSparteId == null && e.artikel.sparte == null) ||
              (chg.seWarengruppeId == null && e.artikel.warengruppe == null)
            ) {
              return true
            }
          }
          return false
        }) != null

      if (needsDefaults) {
        askToConfirm({
          title: 'Defaultwerte für neu zu abonnierende Artikel unvollständig',
          confirmLabel: 'Speichern und Defaultwerte automatisch setzen',
          severity: 'warning',
          message: (
            <span>
              Für neue Artikel müssen Defaultwerte gesetzt werden.
              <Divider style={{ marginTop: 8, marginBottom: 8 }} />
              Sie können Speichern abbrechen und diese Werte manuell setzen oder beim Speichern
              automatisch setzen lassen. Dabei können diese ggf. leer bleiben!
            </span>
          ),
          onConfirm: doHandleSave
        })
      } else {
        doHandleSave()
      }
    } else {
      doHandleSave()
    }
  }

  const aboBetriebsTypModel = useMemo<BetriebstypJson>(
    () => model?.aboBetriebsTyp && getBT(model.aboBetriebsTyp),
    [getBT, model.aboBetriebsTyp]
  )

  const lastEnvelope = useRef<any>(null)
  useEffect(() => {
    if (
      envelope?.filter == null &&
      envelope?.selektion?.folgeArsstId != null &&
      lastEnvelope.current !== envelope
    ) {
      lastEnvelope.current = envelope
      const foundK = envelope.selektion.ergebnisse?.find((e) => e.aenderungenIds?.length > 0)
      const foundZ = envelope.selektion.ergebnisse?.find((e) => e.zusatzAenderungenIds?.length > 0)
      if (foundZ == null) {
        askToConfirm({
          title: 'Wechsel in nächste ArSST Stufe',
          confirmLabel: 'Nächste ArSST Stufe setzen',
          severity: 'warning',
          message: (
            <span>
              {foundK == null
                ? 'In der nächsten ArSST Stufe sind die gleichen Konflikte wie in der aktuellen ArSST Stufe.'
                : 'In der nächsten ArSST Stufe sind keine Konflikte.'}
              <Divider style={{ marginTop: 8, marginBottom: 8 }} />
              Durch Wechsel der Stufe werden mehr Artikeldaten aus der Listung verwendet und bei
              Änderungen zukünftig automatisch aktualisiert.
              <Divider style={{ marginTop: 8, marginBottom: 8 }} />
              Jetzt die neue ArSST Stufe setzen?
            </span>
          ),
          onConfirm: () => {
            onValueChange({ name: 'arsstId', value: envelope.selektion.folgeArsstId })
            setTimeout(() => save(), 250)
          }
        })
      }
    }
  }, [askToConfirm, envelope, filter, onValueChange, save, wait])

  const filterField = useMemo(
    () => (
      <Paper
        style={{
          padding: '2px 16px',
          // marginTop: -8,
          minWidth: 100,
          backgroundColor: filter && pink[darkMode ? 500 : 200]
        }}
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item>Ansichtsfilter: </Grid>
          <Grid item>
            <SelectField
              fullWidth
              options={items(HkmEnum.ArtikelAenderungTyp)}
              renderItem={(opt) => opt.text}
              emptyText="Vollständig"
              value={filter}
              onChange={(e) => {
                setQuery({ artikelBT, filter: e.target.value })
              }}
              disabled={isChanged}
            />
          </Grid>
        </Grid>
      </Paper>
    ),
    [artikelBT, darkMode, filter, isChanged, items, setQuery]
  )

  return (
    <StatePlane uiLock={uiLock} altLink={model.aboId && AppPaths.ArtikelAboPflegeFn(model.aboId)}>
      <Frame space>
        <FrameRow>
          <ArtikelAboSelektionForm
            model={model}
            errors={errors}
            onChange={onValueChange}
            isNew={isNew}
          />
        </FrameRow>
        <FrameBody>
          {!isNew && (
            <ArtikelAboSelektionArtikelTable
              artikelSelektionArSSTId={model.arsstId}
              artikelSelektionId={model.id}
              artikelSelektionenMap={model.artikelSelektionenMap}
              ergebnisse={model.ergebnisse}
              ergebnisseChanges={model.changesMap}
              dispatch={dispatch}
              silentUpd={silentUpd}
              hideZentraleWg={model.typ === ArtikelSelektionType.WARENGRUPPE}
              aboBetriebsTyp={model.aboBetriebsTyp}
              aboBetriebsTypModel={aboBetriebsTypModel}
              konditionDefinition={envelope.kondiDefinition}
              aenderungenIdx={model.aenderungenIdx}
              filterMode={!!filter}
              warengruppen={warengruppen}
              sparten={sparten}
              warengruppenGlobal={warengruppenGlobal}
              spartenGlobal={spartenGlobal}
              zubestaetigenHints={model.zubestaetigenHints}
              lieferant={model.lieferant}
              wgSparteAuswahlManuell={model.wgSparteAuswahlManuell}
              forEinzelhandel={model.forEinzelhandel}
            />
          )}
        </FrameBody>
        <FrameRow>
          <ButtonRow>
            {filterField}
            <div style={{ flexGrow: 1 }} />
            <TooltipWrapper
              title="Listungsänderungen werden automatisch übernommen, nur Konflikte oder Fehler müssen manuell bearbeitet werden"
              arrow
            >
              <Switch
                label="Änderungen automatisch übernehmen"
                checked={model.aenderungenAutomatischUebernehmen}
                name="aenderungenAutomatischUebernehmen"
                onChange={onValueChange}
              />
            </TooltipWrapper>
            {(model.forEinzelhandel || isPIMModel || model.preisaenderungenGetrennt) && (
              <TooltipWrapper
                title="Preisänderungen in einem eigenen Vorgang bearbeiten. Hier werden dann nur Stammdatenänderungen übernommen."
                arrow
              >
                <Switch
                  label="Preisänderungen getrennt bearbeiten"
                  checked={model.preisaenderungenGetrennt}
                  name="preisaenderungenGetrennt"
                  onChange={onValueChange}
                />
              </TooltipWrapper>
            )}
            <UndoButton onClick={undo} canUndo={canUndo} size="small" />
            <SaveButton onClick={handleSave} isNew={isNew} isChanged={isChanged} />
            <ReloadButton onClick={reload} isNew={isNew} isChanged={isChanged} />
          </ButtonRow>
        </FrameRow>
      </Frame>
      <DlgAnker />
    </StatePlane>
  )
}
