import { Grid } from '@mui/material'
import { api } from '@one/api'
import { StandortField } from '@one/components/common/StandortField'
import {
  AuswahlStatus,
  EtikettendruckAnlageEintragJson,
  EtikettenDruckAnlageJson,
  EtikettenDruckEintragJson,
  EtikettenDruckUseCaseJson
} from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { useModelMgr } from '@utils/modelmgr'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { Button } from '@utils/ui/Buttons/Button'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { CardEx } from '@utils/ui/CardEx'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { useCallback, useContext, useState } from 'react'
import { ArtikelEtikettendruckTable } from './ArtikelEtikettendruckTable'
import { ArtikelEtikettendruckVerschiebenDialog } from './ArtikelEtikettendruckVerschiebenDialog'

export interface EtikettenArtikelNode {
  eintrag: EtikettenDruckEintragJson
  status: AuswahlStatus
  gueltigAbVerschobenAuf?: string
}
export interface EtikettenDruckUseCaseEdit extends EtikettenDruckUseCaseJson {
  artikelChangesMap: Map<number, EtikettenArtikelNode>
  vkPreisChangesMap: Map<number, EtikettenArtikelNode>
  hatAusgewaehlt: boolean
}

const isChoosen = (node: EtikettenArtikelNode): boolean =>
  node.status != null && node.status !== AuswahlStatus.AUSGESCHL

const isChoosenX = (node: [number, EtikettenArtikelNode]): boolean => isChoosen(node[1])

export const ArtikelEtikettendruckView = () => {
  const { standortId } = useContext(RouteContext) as any
  const [DlgAnker, dlgShow] = useDialogAnker()
  const [apiCall, apiBusy] = useApiCaller(api)
  const [verschiebareArtikel, setVerschiebareArtikel] = useState([])
  const { raw, model, isNew, isChanged, uiLock, reload, updModel } = useModelMgr<
    EtikettenDruckUseCaseJson,
    EtikettenDruckUseCaseEdit,
    EtikettenDruckUseCaseJson,
    EtikettenDruckAnlageJson
  >({
    id: 'neu',
    api,
    asyncMode: 'async-load',
    noid: true,
    restEdit: 'create',
    title: 'Etikettendruck',
    rest: 'etikettendruck',
    restps: {
      standortId
    },
    init: { eintraegeChangesMap: new Map(), geaenderteArtikel: [], geaenderteVkPreise: [] },
    editMutator: (p) => {
      const checkedArtikel = p.eintraege
        .filter((eintrag) => eintrag.artikelAenderung)
        .map((eintrag) => [eintrag.artikel.id, { eintrag, status: AuswahlStatus.EINGESCHL }])
        .concat(
          p.eintraege
            .filter((eintrag) => eintrag.gueltigAb == null)
            .map((eintrag) => [eintrag.artikel.id, { eintrag, status: AuswahlStatus.EINGESCHL }])
        ) as [number, EtikettenArtikelNode][]

      const checkedVkPreis = p.eintraege
        .filter((eintrag) => eintrag.vkPreisAenderungId)
        .map((eintrag) => [
          eintrag.vkPreisAenderungId,
          { eintrag, status: AuswahlStatus.EINGESCHL }
        ]) as [number, EtikettenArtikelNode][]

      const hatAusgewaehlt = !!(checkedArtikel.find(isChoosenX) || checkedVkPreis.find(isChoosenX))

      return {
        ...p,
        artikelChangesMap: new Map(checkedArtikel),
        vkPreisChangesMap: new Map(checkedVkPreis),
        hatAusgewaehlt
      } as EtikettenDruckUseCaseEdit
    }
  })

  const getEintragStatus = (eintrag: EtikettenDruckEintragJson): AuswahlStatus => {
    return eintrag.vkPreisAenderungId
      ? model.vkPreisChangesMap.get(eintrag.vkPreisAenderungId)?.status
      : model.artikelChangesMap.get(eintrag.artikel.id)?.status
  }

  const setEintragStatus = (selektierte: EtikettenDruckEintragJson[], status: AuswahlStatus) => {
    updModel((old) => {
      const artikelChangesMap = new Map(old.artikelChangesMap)
      const vkPreisChangesMap = new Map(old.vkPreisChangesMap)

      selektierte.forEach((eintrag) => {
        const e = { eintrag, status }
        artikelChangesMap.set(eintrag.artikel.id, e)
        if (eintrag.vkPreisAenderungId) {
          vkPreisChangesMap.set(eintrag.vkPreisAenderungId, e)
        }
      })

      let hatAusgewaehlt = false
      artikelChangesMap.forEach((e) => {
        hatAusgewaehlt ||= isChoosen(e)
      })
      vkPreisChangesMap.forEach((e) => {
        hatAusgewaehlt ||= isChoosen(e)
      })

      return { ...old, artikelChangesMap, vkPreisChangesMap, hatAusgewaehlt }
    })
  }

  const handleVerschieben = useCallback(
    (gueltigAbVerschobenAuf) => {
      if (!gueltigAbVerschobenAuf) {
        return null
      }
      const artikel = verschiebareArtikel.map(
        (ea) =>
          ({
            artikelId: ea.artikel.id,
            vkPreisAenderungsId: ea.vkPreisAenderungId,
            gueltigAb: ea.gueltigAb,
            gueltigAbVerschobenAuf,
            status: ea.status
          }) as EtikettendruckAnlageEintragJson
      )
      apiCall<any>({
        method: 'POST',
        rest: 'etikettendruck/verschieben',
        data: {
          standortId: model.standort.id,
          artikel
        },
        onSuccess: () => {
          reload()
        },
        onSuccessMsg() {
          return `Verschobene Artikel erfolgreich gespeichert`
        },
        onErrorMsg() {
          return `Fehler beim Speichern der verschobenen Artikel`
        }
      })
    },
    [model, apiCall, reload, verschiebareArtikel]
  )

  const handleSave = useCallback(() => {
    const data = {
      standortId: model.standort.id,
      artikel: model.eintraege
        .map((vk) => model.artikelChangesMap.get(vk.artikel.id))
        .concat(model.eintraege.map((vk) => model.vkPreisChangesMap.get(vk.vkPreisAenderungId)))
        .filter(Boolean)
        .filter((e) => e.status == AuswahlStatus.EINGESCHL)
        .filter(
          (e, idx, arr) =>
            arr.findIndex((ee) => ee.eintrag.artikel.id === e.eintrag.artikel.id) === idx
        )
        .map(
          (ea) =>
            ({
              artikelId: ea.eintrag.artikel.id,
              vkPreisAenderungsId: ea.eintrag.vkPreisAenderungId,
              abgelaufeneVkPreisAenderungsIds: ea.eintrag.abgelaufeneVkPreisAenderungIds,
              gueltigAb: ea.eintrag.gueltigAb,
              status: ea.status
            }) as EtikettendruckAnlageEintragJson
        )
    } as EtikettenDruckAnlageJson
    apiCall<any>({
      method: 'POST',
      rest: 'etikettendruck/drucken',
      data,
      onSuccess: () => {
        reload()
      },
      onSuccessMsg() {
        return `Artikel erfolgreich an Druck übergeben`
      },
      onErrorMsg() {
        return `Fehler beim übergeben der Artikel zum Drucken`
      }
    })
  }, [model, apiCall, reload])

  const onVerschieben = useCallback(() => {
    dlgShow((open, onClose) => (
      <ArtikelEtikettendruckVerschiebenDialog open={open} onClose={onClose(handleVerschieben)} />
    ))
  }, [handleVerschieben, dlgShow])

  return (
    <StatePlane uiLock={uiLock}>
      <Frame space>
        <FrameRow>
          <CardEx backLink collapsiable title="Anstehende Artikel für Etikettendruck">
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <StandortField
                  value={model.standort?.id}
                  options={model.standort && [model.standort]}
                  label="Standort"
                  readonly
                  fullWidth
                />
              </Grid>
            </Grid>
          </CardEx>
        </FrameRow>
        <FrameBody>
          <ArtikelEtikettendruckTable
            title="Geänderte Artikel"
            eintraege={raw.eintraege}
            getEintragStatus={getEintragStatus}
            setEintragStatus={setEintragStatus}
            onSelectVerschiebar={setVerschiebareArtikel}
          />
        </FrameBody>
        <FrameRow>
          <ButtonRow>
            <Button
              onClickVoid={handleSave}
              label="Drucken"
              disabled={!isNew || !model.hatAusgewaehlt}
              variant="contained"
            />
            {raw.actionVerschieben?.enabled && (
              <Button
                onClickVoid={onVerschieben}
                label="Verschieben"
                disabled={!verschiebareArtikel.length}
                variant="contained"
                tooltip={'Nur nicht "Sofort wirksam" Artikel können verschoben werden'}
              />
            )}
            <ReloadButton onClick={reload} isChanged={isChanged} />
          </ButtonRow>
        </FrameRow>
      </Frame>
      <DlgAnker />
    </StatePlane>
  )
}
