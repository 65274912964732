import Grid from '@mui/material/Grid2'
import {
  PimArtikelVersionJson,
  PimArtikelVersionMengeneinheitJson,
  PimMengeneinheitDisplayJson
} from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import React, { useMemo } from 'react'
import { Column, DataTable } from '@utils/ui/DataTable/DataTable'
import { StaticField } from '@utils/ui/fields/StaticField'
import { ScrollPanel } from '@utils/ui/ScrollPanel'

export interface PimArtikelMengeneinheitenListProps {
  pimArtikelVersion: PimArtikelVersionJson
  mengeneinheiten?: PimMengeneinheitDisplayJson[]
}

/**
 * PimArtikelMengeneinheiten wird im EigenlistungsArtikelPflegeView editierbar
 * und im PimArtikelView als readonly genutzt.
 * @param pimArtikelVersion
 * @param onChange
 * @param mengeneinheiten
 * @constructor
 */
export const PimArtikelMengeneinheitenList = ({
  pimArtikelVersion,
  mengeneinheiten
}: PimArtikelMengeneinheitenListProps) => {
  const columns = useMemo(
    () =>
      [
        {
          field: 'gtin',
          header: 'EAN'
        },
        {
          field: 'pimMengeneinheit.name',
          header: 'Name',
          tooltip: (row) => row.pimMengeneinheit?.bez
        },
        /*
        {
          field: 'mengeBasismengeneinheit.faktor',
          header: 'Basismengeneinheit',
          align: 'right'
        },
        {
          field: 'pimBasisMengeneinheit.name',
          header: 'Basismengeneinheit-Name',
          tooltip: (row) => row.pimBasisMengeneinheit?.bez
        },
        */
        {
          field: 'pimBasisMengeneinheit.umrechnungInfo',
          header: 'Umrechnung',
          body:(row)=>row.umrechnungInfo
        },
        {
          field: 'laenge',
          header: 'Länge',
          align: 'right'
        },
        {
          field: 'breite',
          header: 'Breite',
          align: 'right'
        },
        {
          field: 'hoehe',
          header: 'Höhe',
          align: 'rigth'
          //type: 'number'
        },
        {
          field: 'gewichtBrutto',
          header: 'Gewicht-Brutto',
          type: 'money3'
        },
        {
          field: 'gewichtNetto',
          header: 'Gewicht-Netto',
          type: 'money3'
        },
        {
          field: 'volumne',
          header: 'Volumen',
          align: 'right'
        },
        {
          field: 'stapelfaktor',
          header: 'Stapelfaktor',
          align: 'right'
        },
        {
          field: 'invertiert',
          header: 'Invertiert'
        },
        {
          field: 'eans',
          header: 'EANs',
          valueGetter: (row) =>
            row.eans.map((ean) => `${ean.gtin}${ean.geloescht ? ' - gelöscht' : ''}`)
        }
      ] as Column<PimArtikelVersionMengeneinheitJson>[],
    []
  )

  const details = (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
        <StaticField
          label="Verkaufsverpackungmengeneinheit"
          value={pimArtikelVersion?.verkaufsverpackungMengeneinheit?.name}
          name="verkaufsverpackungMengeneinheit"
          empty
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
        <StaticField
          label="Einzelmengeneinheit"
          value={pimArtikelVersion?.einzelMengeneinheit?.name}
          name="einzelMengeneinheit"
          empty
        />
      </Grid>
    </Grid>
  )

  return (
    <CardEx
      title="Mengeneinheiten und EANs"
      height="100%"
      name="mengeneinheiten"
      topContent={details}
    >
      <ScrollPanel autoHeight autoHeightMax="600px">
        <DataTable
          name="PimMengenheitenTabelle"
          value={mengeneinheiten}
          columns={columns}
          emptyMessage="Keine Daten"
          dense
        />
      </ScrollPanel>
    </CardEx>
  )
}

export const PimArtikelMengeneinheitenListMemo = React.memo(PimArtikelMengeneinheitenList)
