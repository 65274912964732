import { Table } from '@mui/material'
import { calcElementInViewport, findScrollParent, getFirstOfType } from '@utils/ui/uiutils'
import { aidClear, aidOf, arrayFromSet, asNumber, ensureArray, isString } from '@utils/utils'
import clsx from 'clsx'
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import isEqual from 'react-fast-compare'
import { DataTableAction, DataTableBody, RowClassName, RowStyle } from './DataTableBody'
import { DataTableHeader } from './DataTableHeader'
import { useDataTableStyles } from './DataTableStyles'
import {
  adjustLeftPos,
  amendCol,
  buildColumnKey,
  computeLeftPos,
  descendingComparator,
  filterCollection,
  getComparator,
  getFieldValue,
  nextTableId,
  stableSort
} from './DataTableUtils'

import { useLocalState } from '@utils/localState'
import { useExcelExporter } from '@utils/ui/excel'

export type ActionsPos = 'left' | 'right'

export type ColumnAlign = 'left' | 'center' | 'right'

export type ColumnType =
  | 'number'
  | 'money2'
  | 'money3'
  | 'money4'
  | 'money6'
  | 'date'
  | 'dateunlimited'
  | 'datetime'
  | 'datetimesecond'
  | 'datetimemillisec'
  | 'string'
  | 'boolean'

export interface ColumnFilterTrigger<T = any> {
  isFilter: (col: Column<T>) => number | false
  onFilter: (col: Column<T>, filterRows: (mfc: number) => T[]) => void
  updFilter: (cols: Column<T>[], filterRows: (mfc: number) => T[]) => FilterColumn<T>[]
}

export type FilterFieldData = {
  field: string
  value: any
}

export type FilterType = 'index' | 'exact' | 'range' | 'contains'

export interface FilterColumn<T = any> {
  key: string
  matches?: (row: T, col: Column<T>) => boolean
  //
  type: FilterType
  value?: any
  index?: Map<string, boolean>
}

export type OrderDirection = 'desc' | 'asc' | false

export type DataTableOrderType = {
  key: string
  dir: OrderDirection
  idx?: number
}

/** Extrahiere in Basisvalue, bevorzugt in string, nie in Objekte */
export type ValueGetter<T> = (
  value: T
) => string | string[] | number | number[] | Date | Date[] | boolean | boolean[] | null

export type VerticalAlignType = 'top' | 'middle' | 'bottom'

export type RenderProps<T> = {
  column: Column<T>
  edit?: boolean
  id?: string
  getSelected: () => Set<T>
}

export interface Column<T = any> {
  key?: string
  field: string | string[]
  header?: React.ReactNode
  /** Tooltip des Headers */
  headerTip?: string
  align?: ColumnAlign
  valign?: VerticalAlignType
  type?: ColumnType
  fraction?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
  sortable?: boolean
  /** Spalte initial ausgeblendet, kann eingeblendet werden */
  hidden?: boolean
  /** Spalte wird nachträglich per css ausgeblendet, wenn keine Zelle einen Wert hat */
  hideIfEmpty?: boolean
  /** technisch ausgeblendet, nicht anzeigbar */
  off?: boolean
  /** Inhalt der Zelle ausblenden (Spalte bleibt sichtbar) */
  visible?: (row: T) => boolean
  width?: string
  /** Tooltip der Zelle */
  tooltip?: ((row: T) => string) | string
  /** Ausgabe für Sort und Filter (optional)*/
  valueGetter?: ValueGetter<T>
  /** Ausgabe für Sort (optional) */
  sortGetter?: ValueGetter<T>
  /** spezielle Renderfunktion für Zelle */
  body?: (row: T, props: RenderProps<T>) => React.ReactNode
  backgroundColor?: string
  opacity?: number
  span?: (row: T) => number
  wrapMode?: 'normal' | 'nowrap' | 'pre' | null
  cellPadding?: string | number | object
  decorator?: (row: T, body: React.ReactNode) => React.ReactNode
  exportGetter?: ValueGetter<T>
  exportHeader?: string
  noexport?: boolean
}

export interface RowGrouper<T = any> extends Column<T> {
  groupEmpty?: boolean
  /** Gruppen ohne Wert werden immer oben angezeigt */
  pushEmpty?: boolean
  /** sortiert die Gruppe auf- oder absteigend */
  orderDir?: 'asc' | 'desc'
  /** Spalte wird normal ausgeblendet, kann aber erhalten bleiben */
  dontHideColumn?: boolean
}

type DataTableGlobalConfig = {
  striped?: boolean | null
  borders?: boolean | null
  actionsPos?: ActionsPos
}

export const useDataTableGlobals = () => {
  const [dataTableGlobalConfig, , onDataTableGlobalConfigChange] =
    useLocalState<DataTableGlobalConfig>('tableconfig', {
      striped: false,
      borders: false,
      actionsPos: 'right'
    })
  return { dataTableGlobalConfig, onDataTableGlobalConfigChange }
}

/** I = Inclusive, E = Exclusive */
export type FilterModeType = 'I' | 'E'

export type FilterDef = {
  text: string | undefined | null
  mode: FilterModeType
}

export type ActiveCellType = {
  row: number | null
  col: number | null
  edit: boolean
}

const activeCellEmpty = {
  row: -1,
  col: -1,
  edit: false
}

type LocalType<T> = {
  activeCell: ActiveCellType

  orderBy: any
  groupBy: any
  columnFilter: FilterColumn<T>[]
  globalFilter: any

  grouperRef: any

  lastRow: T

  dataOrg: T[]
  dataFiltered: T[]
  dataSorted: T[]
  dataGrouped: T[]
  dataBase: T[]
  dataBaseWithoutGrouper: T[]
  dataView: T[]

  columnOrder: any[]
}

const localInit = {
  orderBy: null,
  groupBy: null,
  columnFilter: null,
  globalFilter: null,

  grouperRef: new Map(),

  lastRow: null,

  dataOrg: [],
  dataFiltered: [],
  dataSorted: [],
  dataGrouped: [],
  dataBase: [],
  dataBaseWithoutGrouper: [],
  columnOrder: []
} as LocalType<any>

type DataTableFncType<T> = {
  selected: () => Set<T>
  onSelect: (args) => void
  onRowDoubleClick: (row, event) => void
  onReorderRows: (srcPos: number, destPos: number) => void
  filterRows: (maxFilterCol: number) => T[]
}

export type DataTableApi = {
  exportExcel: (filename?: string) => void
}

export interface DataTableProps<T = any> {
  id?: string
  className?: any
  columns?: Column<T>[]
  value?: T[]
  /** Immer notwendig für  UI-Testtool */
  name: string
  dense?: boolean
  size?: 'slim' | 'small' | 'medium'
  selectMode?: 'none' | 'single' | 'multi'
  selectCol?: boolean
  actions?: DataTableAction<T>[]
  onSelect?: (next: Set<T>) => void
  selected?: Set<T>
  focustype?: 'none' | 'row' | 'cell'
  hover?: boolean
  initialOrderBy?: string | string[] | DataTableOrderType[]
  loading?: boolean | number
  globalFilter?: FilterDef
  columnFilter?: FilterColumn<T>[]
  columnFilterTrigger?: ColumnFilterTrigger<T>
  page?: number
  setPage?: (page: number) => void
  rowsPerPage?: number
  stickyHeader?: boolean
  stickyActionCol?: boolean
  stickyColumsLeft?: number
  onDataChange?: (ndv: any) => void
  onRowClick?: (row: T, event: any) => void
  onRowDoubleClick?: (row: T, event: any, col: Column<T>) => void
  rowClassName?: RowClassName<T>
  rowStyle?: RowStyle<T>
  emptyMessage?: React.ReactNode
  groupBy?: RowGrouper<T> | false
  wrapMode?: 'normal' | 'nowrap'
  columnOrder?: string[]
  onReorderRows?: (rows: T[]) => void
  borders?: boolean
  striped?: boolean
  onHeaderContext?: (e: any) => void
  actionsPos?: ActionsPos
  rowFiller?: boolean | string
  apiRef?: React.MutableRefObject<DataTableApi>
}

export const DataTable = <T extends object>({
  id,
  className,
  columns,
  value,
  name,
  dense = false,
  size,
  selectMode,
  selectCol = false,
  actions,
  onSelect,
  selected,
  focustype = 'row',
  hover = false,
  initialOrderBy,
  loading = false,
  globalFilter,
  columnFilter,
  columnFilterTrigger,
  page,
  setPage,
  rowsPerPage,
  stickyHeader = true,
  stickyActionCol = true,
  stickyColumsLeft,
  onDataChange,
  onRowClick,
  onRowDoubleClick,
  rowClassName,
  rowStyle,
  emptyMessage,
  groupBy,
  wrapMode = 'nowrap',
  columnOrder,
  onReorderRows,
  onHeaderContext,
  borders = null,
  striped = null,
  actionsPos,
  rowFiller = false,
  apiRef
}: DataTableProps<T>) => {
  const { classes } = useDataTableStyles() as any

  const { dataTableGlobalConfig } = useDataTableGlobals()

  const localRef = useRef<LocalType<T>>()
  if (localRef.current == null) {
    localRef.current = { ...localInit }
  }

  const excelExporter = useExcelExporter()

  const tableUID = useMemo(() => `DATATABLE${nextTableId()}`, [])

  const [selectedEx, setSelectedEx] = useState(new Set<T>())

  const [activeCell, setActiveCell] = useState<ActiveCellType>(activeCellEmpty)
  localRef.current.activeCell = activeCell

  const actionsLeft = (actionsPos || dataTableGlobalConfig.actionsPos) === 'left'

  const colReorder = onReorderRows != null

  const columnsAmended = useMemo(() => {
    const preCols = []
    if (colReorder) {
      preCols.push({ key: '::reorder' } as Column)
    }
    if (selectCol && ['multi', 'single'].includes(selectMode)) {
      preCols.push({ key: '::select' } as Column)
    }
    if (actionsLeft && actions) {
      preCols.push({ key: '::action' } as Column)
    }

    let cols = columns
      .filter(Boolean)
      .map(amendCol)
      .filter((c) => {
        const isValid =
          !c.off &&
          (!c.hidden || columnOrder?.find((o) => o === c.key) != null) &&
          (!groupBy || groupBy.dontHideColumn || !isEqual(groupBy.field, c.field))
        return isValid
      })

    if (columnOrder != null && columnOrder.length > 0) {
      cols = columnOrder.map((key) => cols.find((col) => col.key === key)).filter(Boolean)
    }
    const postCols = []
    if (rowFiller) {
      postCols.push({
        key: '::filler',
        width: typeof rowFiller === 'string' ? rowFiller : undefined
      } as Column)
    }

    const nextColumns = preCols.concat(cols).concat(postCols)
    return nextColumns as Column<T>[]
  }, [
    colReorder,
    selectCol,
    selectMode,
    actionsLeft,
    actions,
    columns,
    columnOrder,
    rowFiller,
    groupBy
  ])

  const columnsActive = useMemo(() => {
    const valueSafe = value || []
    return columnsAmended.filter((col) => {
      if (col.hideIfEmpty) {
        const foundOne =
          valueSafe.find((row) => {
            const v = col.valueGetter(row)
            return !(
              v == null ||
              (typeof v === 'string' && v.length === 0) ||
              (Array.isArray(v) && v.filter(Boolean).length === 0) ||
              (typeof v === 'boolean' && !v)
            )
          }) != null
        // if (!foundOne) {
        //   debugLog(tableUID, 'emptyColHidden', col.key)
        // }
        return foundOne
      }
      return true
    })
  }, [columnsAmended, value])

  const groupByAmend = useMemo(() => (groupBy ? amendCol<T>(groupBy, 1) : null), [groupBy])

  const [orderBy, setOrderBy] = useState<DataTableOrderType[]>(() =>
    ensureArray(initialOrderBy).map((oi, idx) => ({
      key: isString(oi) ? oi : oi.key,
      dir: (oi.dir == null && 'asc') || (oi.dir === 'desc' && 'desc') || 'asc',
      idx: idx + 1
    }))
  )

  const count = (localRef.current?.dataBase || []).length
  const countWithoutGrouper = (localRef.current?.dataBaseWithoutGrouper || []).length

  const stickyColumsLeftReal =
    (onReorderRows ? 1 : 0) +
    (selectCol && selectMode ? 1 : 0) +
    (stickyColumsLeft || 0) +
    (actionsLeft && actions ? 1 : 0)

  const stickyHints = useMemo(
    () => ({
      header: stickyHeader && classes.stickyheader,
      actionColTH: stickyActionCol && [classes.stickyColTH, classes.stickyActionTH],
      actionColTD: stickyActionCol && [
        classes.stickyColTD,
        classes.stickyActionTD,
        classes.stickySpecialColTD,
        'stickycol'
      ],
      stickyClassTH: (idx: number) => (idx + 1 > stickyColumsLeftReal ? null : classes.stickyColTH),
      stickyClassTD: (idx: number) =>
        idx + 1 > stickyColumsLeftReal ? null : [classes.stickyColTD, 'stickycol']
    }),
    [
      stickyHeader,
      classes.stickyheader,
      classes.stickyColTH,
      classes.stickyActionTH,
      classes.stickyColTD,
      classes.stickyActionTD,
      classes.stickySpecialColTD,
      stickyActionCol,
      stickyColumsLeftReal
    ]
  )

  const tableRef = useRef<HTMLTableElement>()
  const headRef = useRef<HTMLDivElement>()
  const bodyRef = useRef<HTMLDivElement>()

  const fncRefX = useRef<DataTableFncType<T>>()

  useImperativeHandle(
    fncRefX,
    () => ({
      selected: () => selectedEx,
      onSelect: (args) => {
        if (onSelect) {
          onSelect(args)
        }
      },
      onRowDoubleClick: (row, event) => {
        if (onRowDoubleClick) {
          if (event.target.localName === 'tr') {
            onRowDoubleClick(row, event, null)
            return
          }

          const colIdx = getFirstOfType(event.target, 'td')?.getAttribute('col-idx')
          if (colIdx != null && colIdx >= 0 && colIdx < columnsActive.length) {
            const col = columnsActive[colIdx] as Column
            onRowDoubleClick(row, event, col)
          }
        }
      },
      onReorderRows: (srcPos: number, destPos: number) => {
        const data = localRef.current.dataSorted
        if (
          onReorderRows &&
          srcPos >= 0 &&
          srcPos < data.length &&
          destPos >= 0 &&
          destPos <= data.length
        ) {
          const tomove = data[srcPos]
          let copy = [...data]
          copy.splice(srcPos, 1)
          const insertPos = destPos > srcPos ? destPos - 1 : destPos
          if (insertPos > copy.length - 1) {
            copy = [...copy, tomove]
          } else {
            copy.splice(insertPos, 0, tomove)
          }
          onReorderRows(copy)
        }
      },

      filterRows: (maxFilterCol: number) => {
        const cfs =
          maxFilterCol < 0 ? columnFilter : columnFilter.filter((c, i) => i < maxFilterCol)
        return filterCollection(
          localRef.current.dataOrg,
          cfs,
          globalFilter,
          columnsActive,
          groupByAmend
        ) as T[]
      }
    }),
    [
      selectedEx,
      onSelect,
      onRowDoubleClick,
      columnsActive,
      onReorderRows,
      columnFilter,
      globalFilter,
      groupByAmend
    ]
  )

  useImperativeHandle(
    apiRef,
    () => ({
      exportExcel: (fileName?: string) => {
        excelExporter(() => {
          const exportColumns = columnsAmended.filter(
            (col) => !col.noexport && (col.exportGetter || col.valueGetter)
          )
          const header = [
            exportColumns.map(
              (col) => col.exportHeader || (typeof col.header === 'string' && col.header) || ''
            )
          ]
          const body = localRef.current.dataSorted.map((row) =>
            exportColumns.map((col) => ensureArray(col.exportGetter(row)).join(', '))
          )
          return { data: header.concat(body), fileName: fileName || name || 'export' }
        })
      }
    }),
    [excelExporter, columnsAmended, name]
  )

  const onReorderRowInt = useCallback(
    (idx1: number, idx2: number) => fncRefX.current && fncRefX.current.onReorderRows(idx1, idx2),
    []
  )

  const filterRows = useCallback(
    (maxFilterIndex: number) => fncRefX.current && fncRefX.current.filterRows(maxFilterIndex),
    []
  )

  const rowFromEvent = useCallback((event) => {
    const tr = getFirstOfType(event.target, 'tr')
    if (tr == null) {
      return null
    }
    const rowIndex = tr.getAttribute('data-idx')
    if (rowIndex == null) {
      return null
    }
    return localRef.current.dataBase[rowIndex]
  }, [])

  const onRequestSort = useCallback((event, column) => {
    setOrderBy((old) => {
      const ofi = old.findIndex((s) => s.key === column.key)
      let of: DataTableOrderType
      if (ofi !== -1) {
        of = old[ofi]
        of = {
          key: column.key,
          dir: (of.dir === 'asc' && 'desc') || (of.dir === 'desc' ? false : 'asc')
        }
      } else {
        of = {
          key: column.key,
          dir: 'asc'
        }
      }

      let oi: DataTableOrderType[]
      if (event.ctrlKey) {
        if (ofi !== -1) {
          oi = [...old]
          oi.splice(ofi, 1, of)
        } else {
          oi = [...old, of]
        }
      } else {
        oi = [of]
      }

      return oi.filter((o) => o.dir !== false).map((o, idx) => ({ ...o, idx: idx + 1 }))
    })
  }, [])

  const setSelectedHook = useCallback((next: Set<T>) => {
    const selectedX = fncRefX.current.selected()
    if (!isEqual(selectedX, next)) {
      setSelectedEx(next)
      fncRefX.current.onSelect(next)
    }
  }, [])

  const onSelectAllClick = useCallback(
    (event) => {
      if (event.target.checked) {
        const newSelecteds = new Set(localRef.current.dataBaseWithoutGrouper)
        setSelectedHook(newSelecteds)
      } else {
        setSelectedHook(new Set())
      }
    },
    [setSelectedHook]
  )

  const checkSelected = useCallback(
    (row: any) => {
      return selectedEx.has(row)
    },
    [selectedEx]
  )

  const getSelected = useCallback(() => fncRefX.current?.selected() || new Set<T>(), [])

  const onSelectRow = useCallback(
    (row: T) => {
      if (selectMode === 'none') {
        return
      }

      const selectedX = fncRefX.current.selected()

      const next = new Set(selectedX)
      if (selectMode === 'single') {
        next.clear()
      }
      if (selectedX.has(row)) {
        next.delete(row)
      } else {
        next.add(row)
      }
      localRef.current.lastRow = row
      setSelectedHook(next)
    },
    [setSelectedHook, selectMode]
  )

  const onSelectRowClick = useCallback(
    (event) => {
      if (selectMode === 'none') {
        return
      }
      const row = rowFromEvent(event)
      if (row == null) {
        return
      }
      onSelectRow(row)
    },
    [selectMode, rowFromEvent, onSelectRow]
  )

  const onSelectRowEx = useCallback(
    (row, event, click = null) => {
      if (selectMode === 'none') {
        return
      }

      const currentData = localRef.current.dataBase

      const lastRowX = localRef.current.lastRow
      if (selectMode === 'multi' && lastRowX != null && event.shiftKey) {
        let p1 = currentData.indexOf(lastRowX)
        let p2 = currentData.indexOf(row)
        if (p1 >= 0 && p2 >= 0) {
          if (p2 < p1) {
            const t = p1
            p1 = p2
            p2 = t
          }
          setSelectedHook(new Set(currentData.slice(p1, p2 + 1)))
          localRef.current.lastRow = row
        }
      } else if (selectMode === 'multi' && event.ctrlKey) {
        if (click) {
          const selectedX = fncRefX.current.selected()
          const next = new Set(selectedX)
          if (selectedX.has(row)) {
            next.delete(row)
          } else {
            next.add(row)
          }
          localRef.current.lastRow = row
          setSelectedHook(next)
        }
      } else {
        localRef.current.lastRow = row
        const next = new Set<T>()
        next.add(row)
        setSelectedHook(next)
      }
    },
    [setSelectedHook, selectMode]
  )

  const onRowClickHook = useCallback((event) => {
    // switch (event.target?.localName?.toUpperCase()) {
    //   case 'A':
    //   case 'INPUT':
    //   case 'BUTTON':
    //     return
    //   default:
    //     break
    // }
    // const row = rowFromEvent(event)
    // if (row == null) {
    //   return
    // }
    // onSelectRowEx(row, event, true)
    // if (onRowClick) {
    //   onRowClick(row, event)
    // }
  }, [])

  const onRowDoubleClickHook = useCallback(
    (event) => {
      const row = rowFromEvent(event)
      if (row == null) {
        return
      }
      fncRefX.current.onRowDoubleClick(row, event)
    },
    [rowFromEvent]
  )

  const getBoundingRect = useCallback(() => {
    return (
      headRef.current?.getBoundingClientRect() || {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
      }
    )
  }, [])

  const onRowKeyDown = useCallback(
    // eslint-disable-next-line complexity
    (event) => {
      const currentData = localRef.current.dataBase

      const activeCell = localRef.current.activeCell

      const followLine = (tr: HTMLElement) => {
        if (tr) {
          const { top, bottom } = getBoundingRect()
          const scrollEl = findScrollParent(tr)
          const inv = calcElementInViewport(tr, bottom - top)
          scrollEl.scrollTop = scrollEl.scrollTop + inv * 1.7
        }
      }

      const selectOn = (r: HTMLTableRowElement) => {
        if (r) {
          const idx = asNumber(r.getAttribute('data-idx'))
          if (idx >= 0 && idx < currentData.length) {
            const nr = currentData[idx]
            onSelectRowEx(nr, event)
          }
        }
      }

      const focusOn = (r: HTMLTableRowElement) => {
        if (r) {
          if (focustype === 'cell') {
            const cell = r.cells[localRef.current.activeCell.col]
            cell.focus()
          } else {
            r.focus()
          }
        }
      }
      const fieldType = event.target.localName
      const td = getFirstOfType(event.target, 'td')
      const cellIndex = td && td.cellIndex
      const tr = getFirstOfType(event.target, 'tr')
      if (tr == null) {
        return
      }

      const row = rowFromEvent(event)
      if (row == null) {
        return
      }

      const isInput = 'INPUT' === event.target?.localName?.toUpperCase()

      switch (event.keyCode || event.which) {
        case 38: // up arrow
          {
            event.preventDefault()
            let r1 = tr.previousElementSibling
            while (r1 != null && r1.getAttribute('data-grouper')) {
              r1 = r1.previousElementSibling
            }
            if (r1 == null) {
              if (setPage && page > 0) {
                const body = tr.parentElement
                setPage(page - 1)
                setTimeout(() => {
                  body.lastChild.focus()
                  body.lastChild.scrollIntoView(false)
                }, 50)
              }
              return
            }

            followLine(r1)
            selectOn(r1)
            focusOn(r1)
          }
          break

        //@ts-ignore
        case 13: // enter
          if (focustype !== 'cell') {
            if (!isInput) {
              onRowDoubleClickHook(event)
            }
            break
          }
        // break fall through

        case 40: // down arrow
          {
            event.preventDefault()
            let r2 = tr.nextElementSibling
            while (r2 != null && r2.getAttribute('data-grouper')) {
              r2 = r2.nextElementSibling
            }
            if (r2 == null) {
              if (setPage && page < Math.trunc(count / rowsPerPage)) {
                const body = tr.parentElement
                setPage(page + 1)
                setTimeout(() => {
                  body.firstChild.focus()
                  body.firstChild.scrollIntoView(false)
                }, 50)
              }
              return
            }

            followLine(r2)
            selectOn(r2)
            focusOn(r2)
          }
          break

        case 37: // left arrow
          if (focustype === 'cell' && !isInput) {
            event.preventDefault()
            if (activeCell.col > 0) {
              const cells = tr.cells as HTMLTableCellElement[]
              const prev = cells[activeCell.col - 1]
              prev.focus()
            }
          }
          break
        case 39: // right arrow
          if (focustype === 'cell' && !isInput) {
            event.preventDefault()
            const cells = tr.cells as HTMLTableCellElement[]
            if (activeCell.col >= 0 && activeCell.col < cells.length - 1) {
              const prev = cells[activeCell.col + 1]
              prev.focus()
            }
          }
          break

        case 32: // space
          if (!isInput) {
            event.preventDefault()
            onSelectRowEx(row, event, true)
          }
          break

        case 65: // a
          if (event.ctrlKey && selectMode === 'multi') {
            event.preventDefault()
            setSelectedHook(new Set(currentData))
          }
          break

        case 33: // page up
          {
            event.preventDefault()

            const scrollEl = findScrollParent(tr)
            if (scrollEl) {
              const { top, bottom } = scrollEl.getBoundingClientRect()
              const { top: trY } = tr.getBoundingClientRect()
              const skip = bottom - top

              let r = tr.previousElementSibling
              let lastR = r
              while (r != null) {
                if (!r.getAttribute('data-grouper')) {
                  const { top: y } = r.getBoundingClientRect()
                  if (y < trY - skip) {
                    break
                  }
                }
                lastR = r
                r = r.previousElementSibling
              }

              if (lastR) {
                const { top: trY2 } = lastR.getBoundingClientRect()
                scrollEl.scrollTop = scrollEl.scrollTop - (trY - trY2)
                selectOn(lastR)
                focusOn(lastR)
              }
            }
          }
          break

        case 34: // page dn
          {
            event.preventDefault()

            const scrollEl = findScrollParent(tr)
            if (scrollEl) {
              const { top, bottom } = scrollEl.getBoundingClientRect()
              const skip = bottom - top

              const { top: trY } = tr.getBoundingClientRect()

              let r = tr.nextElementSibling
              let lastR = r
              while (r != null) {
                if (!r.getAttribute('data-grouper')) {
                  const { top: y } = r.getBoundingClientRect()
                  if (y > trY + skip) {
                    break
                  }
                }
                lastR = r
                r = r.nextElementSibling
              }

              if (lastR) {
                const { top: trY2 } = lastR.getBoundingClientRect()
                scrollEl.scrollTop = Math.trunc(scrollEl.scrollTop + trY2 - trY)
                selectOn(lastR)
                focusOn(lastR)
              }
            }
          }
          break

        default:
          break
      }
    },
    [
      rowFromEvent,
      getBoundingRect,
      onSelectRowEx,
      focustype,
      selectMode,
      setPage,
      page,
      count,
      rowsPerPage,
      onRowDoubleClickHook,
      setSelectedHook
    ]
  )

  const dataView = useMemo(() => {
    const local = localRef.current
    if (local == null) {
      return []
    }

    let ndv = local.dataFiltered

    // Filter
    if (
      local.dataOrg !== value ||
      !isEqual(local.columnFilter, columnFilter) ||
      !isEqual(local.globalFilter, globalFilter)
    ) {
      ndv = value
      local.dataOrg = value
      local.globalFilter = globalFilter
      if (isEqual(local.columnFilter, columnFilter)) {
        // Wenn Filter gleich, aber anderes sich ändert, dann Filter aktualisieren
        const cf =
          columnFilterTrigger?.updFilter(columnsActive, fncRefX.current.filterRows) || columnFilter
        local.columnFilter = cf
      } else {
        local.columnFilter = columnFilter
      }
      ndv = filterCollection(ndv, local.columnFilter, globalFilter, columnsActive, groupByAmend)
      local.dataFiltered = ndv
    }

    // Sort
    if (local.dataSorted !== ndv || !isEqual(local.orderBy, orderBy)) {
      if (orderBy && orderBy.length > 0) {
        ndv = stableSort(ndv, getComparator(orderBy, columnsActive))
      }
    }

    // Base data filtered & sorted
    if (local.dataSorted !== ndv) {
      local.dataSorted = ndv
      if (onDataChange) {
        setTimeout(() => {
          onDataChange(local.dataSorted)
        }, 120)
      }
    }

    // group data
    if (local.dataGrouped !== ndv || !isEqual(local.groupBy, groupBy)) {
      local.groupBy = groupBy
      if (groupBy) {
        const { field, groupEmpty } = groupBy

        const groups = new Set<any>(
          ndv.map((row: T) => getFieldValue(row, field)).filter((g: any) => g != null || groupEmpty)
        )

        const og = local.grouperRef.current
        const ng = new Map()
        groups.forEach((g) => {
          let old = og?.get(g)
          if (old == null) {
            old = { __grouper: true, id: g }
            aidOf(old)
          }
          ng.set(g, old)
        })

        local.grouperRef.current = ng

        const rowMap = new Map<any, T>(ndv.map((row) => [getFieldValue(row, field), row]))
        ndv = ndv.concat(
          arrayFromSet(groups).map((g) => {
            const c = rowMap.get(g)
            return aidClear({
              ...c,
              ...ng.get(g)
            })
          })
        )

        let comparator = null
        if (groupBy.orderDir) {
          const orderBy: DataTableOrderType[] = [
            {
              key: buildColumnKey(groupBy, 0),
              dir: groupBy.orderDir,
              idx: 0
            }
          ]

          const interalComparator = getComparator(
            orderBy,
            orderBy.map((item) => ({
              key: item.key,
              field: item.key,
              sortGetter: (row: T) => {
                const value = getFieldValue(row, item.key)
                if (Array.isArray(value)) {
                  return value.join(';')
                }
                return value
              }
            }))
          )
          comparator = (a, b) => {
            const res = interalComparator(a, b)
            const groupByFieldA = getFieldValue(a, field)
            const groupByFieldB = getFieldValue(b, field)

            if (groupBy.pushEmpty) {
              if (groupByFieldA === null && groupByFieldB === null) {
                if (b.__grouper) {
                  return 1
                }
                if (a.__grouper) {
                  return -1
                }
                return 0
              }

              if (groupByFieldA === null) {
                return -1
              }

              if (groupByFieldB === null) {
                return 1
              }
            }
            if (res === 0) {
              if (a.__grouper && b.__grouper) {
                return res
              }
              if (a.__grouper) {
                return -1
              }
              if (b.__grouper) {
                return 1
              }
            }
            return res
          }
        } else {
          comparator = (a, b) => {
            const d = descendingComparator(a, b, (row: any) => getFieldValue(row, field))
            if (a.__grouper === b.__grouper) {
              return d
            }
            if (d === 0) {
              if (a.__grouper) {
                return -1
              }
              if (b.__grouper) {
                return +1
              }
            }
            return d
          }
        }

        ndv = stableSort(ndv as any, comparator)
      }
      local.dataGrouped = ndv
    }

    local.dataBase = ndv
    local.dataBaseWithoutGrouper = ndv.filter((row: any) => !row.__grouper)

    if (setPage && ndv.length < page * rowsPerPage) {
      setPage(0)
      local.lastRow = null
    }

    if (page != null && rowsPerPage) {
      ndv = ndv.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }

    if (!isEqual(ndv, local.dataView)) {
      local.lastRow = (local.lastRow && ndv.indexOf(local.lastRow) !== -1 && local.lastRow) || null
      local.dataView = ndv
      return ndv
    }

    return local.dataView
  }, [
    value,
    columnFilter,
    globalFilter,
    orderBy,
    groupBy,
    setPage,
    page,
    rowsPerPage,
    columnsActive,
    groupByAmend,
    columnFilterTrigger,
    onDataChange
  ])

  useEffect(() => {
    const local = localRef.current
    if (local == null) {
      return
    }

    // prüft, ob die Werte der Selektion noch mit den Werten der Tabelle übereinstimmen
    const isSelectionInvalid = () => {
      const v = selected?.values()?.next().value
      if (v == null) {
        return false
      }
      return local.dataOrg?.find((r) => r === v) == null
    }

    if (!isEqual(local.dataOrg, value) || isSelectionInvalid()) {
      if (setPage) {
        setPage(0)
      }

      if (value) {
        const dupCheck = new Set()
        value.forEach((r) => {
          const ident = aidOf(r)
          if (dupCheck.has(ident)) {
            console.error('Duplicate rows', value)
            throw new Error('Duplicate rows - check idenityFn or do not use cloned rows!')
          }
          dupCheck.add(ident)
        })
      }

      // Selektion auf ggf neue Kopien korrigieren
      const selEx = fncRefX.current && fncRefX.current.selected()

      if (value != null && value.length > 0 && selEx && selEx.size > 0) {
        const ns = new Set<T>()
        const byIdent = new Set(Array.from(selEx.values()).map((i) => aidOf(i)))
        value.forEach((v) => {
          const s = byIdent.has(aidOf(v))
          if (s) {
            ns.add(v)
          }
        })
        if (!isEqual(ns, selEx)) {
          setSelectedHook(ns)
        }
      } else {
        setSelectedHook(new Set())
      }
    }
  }, [setPage, setSelectedHook, selected, value])

  useEffect(() => {
    if (selected != null && selected instanceof Set && !isEqual(selected, selectedEx)) {
      setSelectedEx(selected)
    }
  }, [selected, selectedEx])

  useEffect(() => {
    const keydown = (e: KeyboardEvent) => {
      if (e.key === 'Shift' && !e.repeat && tableRef.current) {
        tableRef.current.style.userSelect = 'none'
      }
    }
    const keyup = (e: KeyboardEvent) => {
      if (e.key === 'Shift' && tableRef.current) {
        tableRef.current.style.userSelect = null
      }
    }

    const mouseup = (e: MouseEvent) => {}

    const mousedown = (e: MouseEvent) => {
      const target = e.target as Element

      switch (target?.localName?.toUpperCase()) {
        case 'A':
        case 'INPUT':
        case 'BUTTON':
          return
        default:
          break
      }

      // verhindert auslösen der Selektion bei groupBy-Zeilen
      const closestTr = target.closest('tr')
      if (
        target &&
        target.localName !== 'tr' &&
        tableRef.current?.contains(target) &&
        !target.getAttribute('data-selbox') &&
        !closestTr.getAttribute('data-grouper')
      ) {
        const tr = getFirstOfType(target, 'tr')
        if (tr == null) {
          return
        }
        const rowIndex = tr.getAttribute('data-idx')
        if (rowIndex == null) {
          return
        }
        const row = localRef.current.dataBase[rowIndex]
        const selectedX = fncRefX.current.selected()
        // if (!selectedX.has(row)) {
        onSelectRowEx(row, e, true)
        // }
      }
    }

    const handleFocusIn = (e: any) => {
      switch (e.target?.localName?.toUpperCase()) {
        case 'TR':
        case 'TD':
          break
        default:
          return
      }

      const tr = getFirstOfType(e.target, 'tr')
      if (tr) {
        const td = getFirstOfType(e.target, 'td')
        setActiveCell((old) => ({
          ...old,
          row: asNumber(tr?.getAttribute('data-idx')),
          col: asNumber(td?.getAttribute('col-idx')),
          edit: td !== e.target
        }))
      }
    }

    const body = bodyRef.current
    window.document.addEventListener('keydown', keydown)
    window.document.addEventListener('keyup', keyup)
    window.document.addEventListener('mousedown', mousedown, true)
    window.document.addEventListener('mouseup', mouseup, true)
    body.addEventListener('focusin', handleFocusIn, true)

    return () => {
      window.document.removeEventListener('keydown', keydown)
      window.document.removeEventListener('keyup', keyup)
      window.document.removeEventListener('mousedown', mousedown)
      window.document.removeEventListener('mouseup', mouseup)
      body.removeEventListener('focusin', handleFocusIn)
    }
  }, [onSelectRowEx])

  useLayoutEffect(() => {
    if (headRef.current && bodyRef.current) {
      const thead = headRef.current as any
      if (thead.rows.length > 0) {
        const colCount = columnsActive.length + (actions && actions.length ? 1 : 0)
        const lefts = computeLeftPos(thead.rows[0], colCount, stickyColumsLeftReal)
        adjustLeftPos(thead.rows, lefts)
        const tbody = bodyRef.current as any
        adjustLeftPos(tbody.rows, lefts)
      }
    }
  })

  const rowStyleFn = useCallback(
    (row: T, index: number) => (typeof rowStyle === 'function' ? rowStyle(row, index) : rowStyle),
    [rowStyle]
  )

  const borderShow = borders == null ? dataTableGlobalConfig.borders : borders

  const tableClassName = useMemo(
    () =>
      clsx(
        classes.table,
        className,
        (dense || size === 'slim') && classes.denseCols,
        (wrapMode === 'nowrap' && classes.nowrap) || (wrapMode === 'normal' && classes.normalwrap),
        borderShow && classes.borders,
        (striped == null ? dataTableGlobalConfig.striped : striped) && classes.striped,
        focustype === 'cell' && classes.focusCell
      ),
    [
      classes.table,
      classes.denseCols,
      classes.nowrap,
      classes.normalwrap,
      classes.borders,
      classes.striped,
      classes.focusCell,
      className,
      dense,
      size,
      wrapMode,
      borderShow,
      striped,
      dataTableGlobalConfig.striped,
      focustype
    ]
  )
  const head = useMemo(
    () => (
      <DataTableHeader
        headRef={headRef}
        tableUID={tableUID}
        classes={classes}
        columns={columnsActive}
        numSelected={selectedEx.size}
        selectMode={selectMode}
        selectCol={selectCol}
        orderBy={orderBy}
        onSelectAllClick={onSelectAllClick}
        onRequestSort={onRequestSort}
        rowCount={countWithoutGrouper}
        actionCol={!actionsLeft && actions != null && actions.length > 0}
        loading={!!loading}
        stickyHeader={stickyHeader}
        stickyHints={stickyHints}
        columnFilterTrigger={columnFilterTrigger}
        onContextMenu={onHeaderContext}
        filterRows={filterRows}
      />
    ),
    [
      tableUID,
      classes,
      columnsActive,
      selectedEx.size,
      selectMode,
      selectCol,
      orderBy,
      onSelectAllClick,
      onRequestSort,
      countWithoutGrouper,
      actionsLeft,
      actions,
      loading,
      stickyHeader,
      stickyHints,
      columnFilterTrigger,
      onHeaderContext,
      filterRows
    ]
  )

  const body = useMemo(
    () => (
      <DataTableBody<T>
        name={name}
        bodyRef={bodyRef}
        tableUID={tableUID}
        onKeyDown={onRowKeyDown}
        hover={hover}
        focustype={focustype}
        classes={classes}
        columns={columnsActive}
        rowClassName={rowClassName}
        rowStyleFn={rowStyleFn}
        data={dataView}
        offset={page != null && rowsPerPage != null ? page * rowsPerPage : 0}
        selectMode={selectMode}
        selectCol={selectCol}
        checkSelected={checkSelected}
        onSelectRowClick={onSelectRowClick}
        getSelected={getSelected}
        onRowClick={onRowClickHook}
        onRowDoubleClick={onRowDoubleClickHook}
        actions={actions}
        emptyMessage={loading ? 'Lade...' : emptyMessage}
        stickyHints={stickyHints}
        groupBy={groupByAmend}
        onReorderRows={onReorderRowInt}
        actionCol={!actionsLeft}
        activeCell={activeCell}
        setActiveCell={setActiveCell}
        borders={borderShow}
      />
    ),
    [
      name,
      tableUID,
      onRowKeyDown,
      hover,
      focustype,
      classes,
      columnsActive,
      rowClassName,
      rowStyleFn,
      dataView,
      page,
      rowsPerPage,
      selectMode,
      selectCol,
      checkSelected,
      onSelectRowClick,
      getSelected,
      onRowClickHook,
      onRowDoubleClickHook,
      actions,
      loading,
      emptyMessage,
      stickyHints,
      groupByAmend,
      onReorderRowInt,
      actionsLeft,
      activeCell,
      borderShow
    ]
  )

  const tableSize = (size === 'small' && 'small') || (size === 'medium' && 'medium') || null

  return (
    <Table
      ref={tableRef}
      className={tableClassName}
      aria-labelledby="tableTitle"
      size={tableSize}
      aria-label="data table"
      name={name}
      id={id}
      component={undefined}
    >
      {head}
      {body}
    </Table>
  )
}
