import { Grid2 as Grid } from '@mui/material'
import { LieferantAdresseJson, SeLieferantJson } from '@one/typings/apiTypings'
import { ValueChangeFn } from '@utils/modelmgr'
import { CardEx } from '@utils/ui/CardEx'
import { TextField } from '@utils/ui/fields/TextField'
import React, { MouseEventHandler } from 'react'
import { Button } from '@utils/ui/Buttons/Button'

export type LieferantAdresseProps = {
  title: string
  name: string
  adresse: LieferantAdresseJson
  onValueChange: ValueChangeFn<SeLieferantJson>
  hideName?: boolean
  addAdresse?: MouseEventHandler<HTMLButtonElement>
  remAdresse?: MouseEventHandler<HTMLButtonElement>
}

export const LieferantAdresse = ({
  title,
  name,
  adresse,
  onValueChange,
  hideName,
  addAdresse,
  remAdresse
}: LieferantAdresseProps) => {
  return (
    <CardEx
      title={title}
      topActions={
        adresse &&
        adresse.id == null && <Button label="Entfernen" onClick={remAdresse} color="warning" />
      }
    >
      <Grid container spacing={2}>
        {adresse != null ? (
          <>
            {!hideName && (
              <Grid container spacing={2} size={{ xs: 12 }}>
                <Grid size={{ xs: 6, lg: 3, xl: 2 }}>
                  <TextField
                    label="Name 1"
                    name="adresse.name1"
                    value={adresse?.name1}
                    onChange={onValueChange}
                    maxLength={100}
                    fullWidth
                  />
                </Grid>
                <Grid size={{ xs: 6, lg: 3, xl: 2 }}>
                  <TextField
                    label="Name 2"
                    name="adresse.name2"
                    value={adresse?.name2}
                    onChange={onValueChange}
                    maxLength={100}
                    fullWidth
                  />
                </Grid>
                <Grid size={{ xs: 6, lg: 3, xl: 2 }}>
                  <TextField
                    label="Name 3"
                    value={adresse?.name3}
                    name="adresse.name3"
                    onChange={onValueChange}
                    maxLength={100}
                    fullWidth
                  />
                </Grid>
              </Grid>
            )}
            <Grid container spacing={2} size={{ xs: 12 }}>
              <Grid size={{ xs: 6, lg: 3, xl: 2 }}>
                <TextField
                  label="Strasse"
                  name={name + '.strasse'}
                  value={adresse?.strasse}
                  onChange={onValueChange}
                  maxLength={100}
                  fullWidth
                />
              </Grid>
              <Grid size={{ xs: 3, lg: 1, xl: 1 }}>
                <TextField
                  label="Land"
                  name={name + '.land'}
                  value={adresse?.land}
                  onChange={onValueChange}
                  maxLength={2}
                  regex={/^[A-Z]*$/}
                  fullWidth
                />
              </Grid>
              <Grid size={{ xs: 3, lg: 2, xl: 1 }}>
                <TextField
                  label="Plz"
                  name={name + '.plz'}
                  value={adresse?.plz}
                  onChange={onValueChange}
                  maxLength={20}
                  fullWidth
                />
              </Grid>
              <Grid size={{ xs: 6, lg: 3, xl: 2 }}>
                <TextField
                  label="Ort"
                  name={name + '.ort'}
                  value={adresse?.ort}
                  onChange={onValueChange}
                  maxLength={50}
                  fullWidth
                />
              </Grid>
              <Grid size={{ xs: 6, lg: 3, xl: 2 }}>
                <TextField
                  label="Ortsteil"
                  name={name + '.ortsteil'}
                  value={adresse?.ortsteil}
                  onChange={onValueChange}
                  maxLength={50}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} size={{ xs: 12 }}>
              <Grid size={{ xs: 6, lg: 3, xl: 2 }}>
                <TextField
                  label="Postfach"
                  name={name + '.postfach'}
                  value={adresse?.postfach}
                  onChange={onValueChange}
                  maxLength={20}
                  fullWidth
                />
              </Grid>
              <Grid size={{ xs: 6, lg: 3, xl: 2 }}>
                <TextField
                  label="Postfach Ort"
                  name={name + '.postfachOrt'}
                  value={adresse?.postfachOrt}
                  onChange={onValueChange}
                  maxLength={50}
                  fullWidth
                />
              </Grid>
              <Grid size={{ xs: 6, lg: 3, xl: 2 }}>
                <TextField
                  label="Postfach Plz"
                  name={name + '.postfachPlz'}
                  value={adresse?.postfachPlz}
                  onChange={onValueChange}
                  maxLength={10}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} size={{ xs: 12 }}>
              <Grid size={{ xs: 6, lg: 3, xl: 2 }}>
                <TextField
                  label="E-Mail"
                  name={name + '.email'}
                  value={adresse?.email}
                  onChange={onValueChange}
                  maxLength={80}
                  fullWidth
                />
              </Grid>
              <Grid size={{ xs: 6, lg: 3, xl: 2 }}>
                <TextField
                  label="Internet"
                  name={name + '.internet'}
                  value={adresse?.internet}
                  onChange={onValueChange}
                  maxLength={80}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid size={{ xs: 6, lg: 3, xl: 2 }}>
                <TextField
                  label="Telefon 1"
                  name={name + '.tel1'}
                  value={adresse?.tel1}
                  onChange={onValueChange}
                  maxLength={50}
                  fullWidth
                />
              </Grid>
              <Grid size={{ xs: 6, lg: 3, xl: 2 }}>
                <TextField
                  label="Telefon 2"
                  name={name + '.tel2'}
                  value={adresse?.tel2}
                  onChange={onValueChange}
                  maxLength={50}
                  fullWidth
                />
              </Grid>
              <Grid size={{ xs: 6, lg: 3, xl: 2 }}>
                <TextField
                  label="Fax"
                  name={name + '.fax'}
                  value={adresse?.fax}
                  onChange={onValueChange}
                  maxLength={50}
                  fullWidth
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid>
            <Button label="Anlegen" onClick={addAdresse} />
          </Grid>
        )}
      </Grid>
    </CardEx>
  )
}

export const LieferantAdresseMemo = React.memo(LieferantAdresse)
