import { AsyncTaskJson, UseCaseStateJson } from '@one/typings/apiTypings'

export enum UILockMode {
  WAIT = 'WAIT',
  ASYNC_WAIT = 'ASYNC_WAIT',
  RETRY = 'RETRY',
  ERROR = 'ERROR'
}

export type UILockType = {
  mode: UILockMode

  waitMessage?: string

  error?: UseCaseStateJson
  asyncTask?: AsyncTaskJson
  title?: string

  cancelled?: boolean
  cancelCallback?: () => void

  retryCallback?: () => void

  onClose?: () => void

  reload?: () => void
}

export const noLock = {} as UILockType
