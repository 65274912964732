import { UserRoles } from '@one/UserRoles'
import {
  ArtikelBetriebstypCell,
  getArtikelBetriebstypLabel
} from '@one/components/common/ArtikelBetriebstypCell'
import { LieferantCell } from '@one/components/common/LieferantCell'
import { formatLieferant } from '@one/components/common/formatters'
import { useArtikelBetriebstypCache } from '@one/datacaches/useArtikelBetriebsTypeCache'
import { ArtikelAboDisplayJson } from '@one/typings/apiTypings'
import { SearcherResultType } from '@utils/searcher'
import { AppContext } from '@utils/ui/App/AppContext'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { useContext, useMemo } from 'react'

export interface ArtikelAboUebersichtTableProps {
  result: SearcherResultType<ArtikelAboDisplayJson>
  onDelete: (row: any) => void
}

export const ArtikelAboUebersichtTable = ({ result, onDelete }: ArtikelAboUebersichtTableProps) => {
  const { isPIMModel } = useContext(AppContext)
  const { get: getBetriebstyp } = useArtikelBetriebstypCache()
  const tableActions = useMemo(
    (): DataTableAction[] => [
      {
        icon: 'edit',
        tooltip: 'Bearbeiten',
        getLink: (data) => {
          return `/artikelabo/${data.id}`
        }
      },
      {
        icon: 'delete',
        tooltip: 'Löschen',
        handler: onDelete,
        role: UserRoles.STAMMDATEN_EDITOR
      }
    ],
    [onDelete]
  )

  const columns = useMemo<Column<ArtikelAboDisplayJson>[]>(
    () => [
      {
        field: 'name',
        header: 'Abo-Name',
        sortable: true
      },
      {
        field: 'betriebsTyp',
        off: isPIMModel,
        header: 'B-Typ',
        width: '3em',
        sortable: true,
        body: (row) => <ArtikelBetriebstypCell value={row.betriebsTyp} />,
        valueGetter: (row) => `${getArtikelBetriebstypLabel(getBetriebstyp(row.betriebsTyp))}`
      },
      {
        field: ['lieferant.name1', 'lieferant.ort', 'lieferant.nummer'],
        header: 'Lieferant',
        valueGetter: (row) => formatLieferant(row.lieferant),
        body: (row: any) => <LieferantCell lieferant={row.lieferant} asLink />,
        sortable: true
      },
      {
        field: 'countSelektionen',
        header: 'Selektionen',
        sortable: true,
        type: 'number',
        hideIfEmpty: true
      },
      {
        field: 'countArtikel',
        header: 'Abonnierte Artikel',
        sortable: true,
        type: 'number',
        hideIfEmpty: true
      },
      {
        field: 'letztePflege',
        header: 'Letzte Pflege',
        sortable: true,
        type: 'datetime'
      },
      {
        field: 'letzteVerteilung',
        header: 'Letzte Verteilung',
        type: 'number'
      }
    ],
    [getBetriebstyp, isPIMModel]
  )

  const actions = useMemo(
    () => [
      {
        role: UserRoles.STAMMDATEN_EDITOR,
        tooltip: 'Artikelabo hinzufügen',
        navlink: '/artikelabo/neu',
        icon: 'add'
      }
    ],
    []
  )

  return (
    <DataTableCard
      name="ArtikelAboUebersichtTable"
      title="Artikelabonnements"
      filterMode="both"
      localStateName="ArtikelAboUebersichtTable"
      topActions={actions}
      columns={columns}
      actions={tableActions}
      initialOrderBy="name"
      dense
      rowFiller
      result={result}
    />
  )
}
