import { FiberManualRecord, StarBorder } from '@mui/icons-material'
import Grid from '@mui/material/Grid2'
import { AppPaths } from '@one/AppPaths'
import { UserRoles } from '@one/UserRoles'
import {
  ArtikelDefaultColumnsProps,
  useArtikelColumns
} from '@one/components/Artikel/ArtikelColumn'
import { EkKonditionenDefinitionEx } from '@one/components/EkPreis/EKPreisPflege/model/EkPreiseTypes'
import { useArtikelLabelEdit } from '@one/components/Label/useArtikelLabelEdit'
import { WerteCell } from '@one/components/common/DataPreisCell'
import { getArchiviertRowStyle } from '@one/components/common/TableUtils'
import {
  EkKonditionenRabattgruppeKopfJson,
  EkPreiseMasseBearbeitenEintragJson
} from '@one/typings/apiTypings'
import { Action } from '@utils/ui/Action'
import { AppContext } from '@utils/ui/App/AppContext'
import { AppLink } from '@utils/ui/App/AppLink'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { Permission } from '@utils/ui/Permission'
import { ThemeContext } from '@utils/ui/Theme'
import { TooltipEx } from '@utils/ui/TooltipWrapper'
import { formatInteger, formatMoney2, safeArray } from '@utils/utils'
import { CSSProperties, useCallback, useContext, useMemo } from 'react'
import { EkPreiseMasseBearbeitenEintragExJson } from './model/EkPreisMassenPflegeModel'
import { blueGrey, red } from '@mui/material/colors'

const formatRabattgruppe = (rabattgruppe: EkKonditionenRabattgruppeKopfJson) => {
  return rabattgruppe?.name
}

export type EkPreisMassenPflegeTableProps = {
  value?: EkPreiseMasseBearbeitenEintragJson[]
  selected?: Set<EkPreiseMasseBearbeitenEintragJson>
  preisEbeneId?: number
  reload?: any
  onSelect?: any
  bottomActions?: Action[]
  preislisteId?: number
  title?: string
  editMode?: boolean
  loading?: boolean
  readOnly?: boolean
  kondiDefinition: EkKonditionenDefinitionEx
  emptyMessage?: string
}

export const EkPreisMassenPflegeTable = ({
  value,
  selected,
  preisEbeneId,
  onSelect,
  bottomActions,
  preislisteId,
  reload,
  title = 'Einkaufspreise',
  editMode,
  loading,
  readOnly,
  kondiDefinition,
  emptyMessage
}: EkPreisMassenPflegeTableProps) => {
  const { openLabelsEdit } = useArtikelLabelEdit()

  const { isAllianz } = useContext(AppContext)

  const isPreisEbeneMode = preisEbeneId != null

  const { darkMode } = useContext(ThemeContext)

  const columnProps = useMemo<ArtikelDefaultColumnsProps<EkPreiseMasseBearbeitenEintragExJson>>(
    () => ({
      fieldWrapper: {
        wrapper: 'artikel'
      },
      removeDefaultsByField: ['minEinLager', 'lagerME', 'hauptlieferant'],
      addColumnToIndex: [
        !isPreisEbeneMode && {
          beforeField: 'artikel.hageNummer',
          column: {
            type: 'string',
            field: 'standortNr',
            align: 'right',
            header: isAllianz ? 'PE-Nr.' : 'Standort-Nr.',
            headerTip: isAllianz ? 'Nummer der Preisebene' : null,
            valueGetter: (row) => row.preisebene?.nr
          }
        },
        !isPreisEbeneMode && {
          beforeField: 'artikel.hageNummer',
          column: {
            type: 'string',
            field: 'standortBez',
            headerTip: isAllianz ? 'Name der Preisebene' : null,
            header: isAllianz ? 'PE-Name' : 'Standort',
            valueGetter: (row) => row.preisebene?.alias || row.preisebene?.name
          }
        },
        {
          beforeField: 'artikel.hageNummer',
          column: {
            type: 'boolean',
            field: 'neu',
            header: 'V',
            align: 'center',
            headerTip: 'Vorlage zur Preiserfassung, Preisblatt existiert noch nicht.',
            body: (row) => (row.neu ? <StarBorder style={{ fontSize: '100%' }} /> : null)
          }
        }
      ],
      additionalColumns: [
        { type: 'string', field: 'einheit', header: 'Einheit' },
        {
          type: 'string',
          field: 'staffelgruppe',
          header: 'SG',
          headerTip: 'Staffelgruppe',
          valueGetter: (row) => row.staffelgruppe?.name,
          body: (row) =>
            row.staffelgruppe && (
              <TooltipEx
                arrow
                title={
                  <Grid container spacing={1}>
                    {row.staffelgruppe.staffelmengen?.length > 0 ? <Grid>Werte:</Grid> : null}
                    {row.staffelgruppe.staffelmengen?.map((w, idx) => (
                      <Grid key={w}>
                        <span>{formatMoney2(w)}</span>
                      </Grid>
                    ))}
                    <Grid>{row.staffelgruppe.flex ? 'flex' : 'fix'}</Grid>
                  </Grid>
                }
              >
                <div>{row.staffelgruppe.name}</div>
              </TooltipEx>
            )
        },
        {
          field: 'staffelmenge',
          header: 'Staffel',
          headerTip: 'Staffelmenge',
          cellPadding: 0,
          type: 'number',
          valueGetter: (row) => row.werte?.map((w) => w.staffelmenge),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="staffelmenge"
              formatter={(v, w) => {
                if (v == null) {
                  return null
                }
                if (w.hauptstaffel) {
                  return (
                    <span title="Hauptstaffel">
                      <FiberManualRecord style={{ fontSize: '60%', paddingBottom: '1px' }} />
                      {formatInteger(v)}
                    </span>
                  )
                }
                return <>{formatInteger(v)}</>
              }}
              darkMode={darkMode}
            />
          )
        },
        {
          field: 'listenpreis',
          header: kondiDefinition.listenpreisLabel,
          headerTip: kondiDefinition.listenpreisTip,
          cellPadding: 0,
          type: 'money2',
          valueGetter: (row) => row.werte?.map((w) => w.listenpreis),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="listenpreis"
              formatter={formatMoney2}
              darkMode={darkMode}
            />
          )
        },
        {
          field: 'sonderpreis',
          header: kondiDefinition.sonderpreisLabel,
          headerTip: kondiDefinition.sonderpreisTip,
          cellPadding: 0,
          type: 'money2',
          valueGetter: (row) => row.werte?.map((w) => w.sonderpreis),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="sonderpreis"
              formatter={formatMoney2}
              darkMode={darkMode}
            />
          )
        },
        {
          field: 'freiHaus',
          header: kondiDefinition.ekKondiFreiHausLabel,
          headerTip: kondiDefinition.ekKondiFreiHausTip,
          cellPadding: 0,
          type: 'money2',
          valueGetter: (row) => row.werte?.map((w) => w.freiHaus),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="freiHaus"
              formatter={formatMoney2}
              darkMode={darkMode}
            />
          )
        },
        {
          field: 'lastFreiHaus',
          header: kondiDefinition.ekKondiFreiHausLabel + ' V',
          headerTip: kondiDefinition.ekKondiFreiHausTip + ' - Vorheriger Wert',
          cellPadding: 0,
          type: 'money2',
          valueGetter: (row) => row.werte?.map((w) => w.lastFreiHaus),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="lastFreiHaus"
              formatter={formatMoney2}
              darkMode={darkMode}
            />
          )
        },
        {
          field: 'delta',
          header: kondiDefinition.ekKondiFreiDiffLabel,
          headerTip: kondiDefinition.ekKondiFreiDiffTip,
          cellPadding: 0,
          type: 'money2',
          valueGetter: (row) => row.werte?.map((w) => w.delta),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="delta"
              formatter={formatMoney2}
              darkMode={darkMode}
            />
          )
        },
        {
          field: 'abs1',
          header: kondiDefinition.zuschlAbs1Label,
          headerTip: kondiDefinition.zuschlAbs1Tip,
          cellPadding: 0,
          visible: (row) => !isAllianz,
          type: 'money2',
          valueGetter: (row) => row.werte?.map((w) => w.abs1),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="abs1"
              formatter={formatMoney2}
              darkMode={darkMode}
            />
          )
        },
        {
          field: 'abs2',
          header: kondiDefinition.zuschlAbs2Label,
          headerTip: kondiDefinition.zuschlAbs2Tip,
          cellPadding: 0,
          visible: (row) => !isAllianz,
          type: 'money2',
          valueGetter: (row) => row.werte?.map((w) => w.abs2),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="abs2"
              formatter={formatMoney2}
              darkMode={darkMode}
            />
          )
        },
        {
          field: 'proz1',
          header: kondiDefinition.rabattProz1Label,
          headerTip: kondiDefinition.rabattProz1Tip,
          cellPadding: 0,
          type: 'money2',
          valueGetter: (row) => row.werte?.map((w) => w.proz1),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="proz1"
              formatter={formatMoney2}
              darkMode={darkMode}
            />
          )
        },
        {
          field: 'proz2',
          header: kondiDefinition.rabattProz2Label,
          headerTip: kondiDefinition.rabattProz2Tip,
          cellPadding: 0,
          type: 'money2',
          valueGetter: (row) => row.werte?.map((w) => w.proz2),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="proz2"
              formatter={formatMoney2}
              darkMode={darkMode}
            />
          )
        },
        {
          field: 'proz3',
          header: kondiDefinition.rabattProz3Label,
          headerTip: kondiDefinition.rabattProz3Tip,
          cellPadding: 0,
          type: 'money2',
          valueGetter: (row) => row.werte?.map((w) => w.proz3),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="proz3"
              formatter={formatMoney2}
              darkMode={darkMode}
            />
          )
        },
        {
          field: 'proz4',
          header: kondiDefinition.rabattProz4Label,
          headerTip: kondiDefinition.rabattProz4Tip,
          cellPadding: 0,
          type: 'money2',
          valueGetter: (row) => row.werte?.map((w) => w.proz4),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="proz4"
              formatter={formatMoney2}
              darkMode={darkMode}
            />
          )
        },
        {
          field: 'proz5',
          header: kondiDefinition.rabattProz5Label,
          headerTip: kondiDefinition.rabattProz5Tip,
          cellPadding: 0,
          type: 'money2',
          valueGetter: (row) => row.werte?.map((w) => w.proz5),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="proz5"
              formatter={formatMoney2}
              darkMode={darkMode}
            />
          )
        },
        {
          field: 'abs3',
          header: kondiDefinition.zuschlAbs3Label,
          headerTip: kondiDefinition.zuschlAbs3Tip,
          cellPadding: 0,
          visible: (row) => !isAllianz,
          type: 'money2',
          valueGetter: (row) => row.werte?.map((w) => w.abs3),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="abs3"
              formatter={formatMoney2}
              darkMode={darkMode}
            />
          )
        },
        {
          field: 'abs4',
          header: kondiDefinition.zuschlAbs4Label,
          headerTip: kondiDefinition.zuschlAbs4Tip,
          cellPadding: 0,
          visible: (row) => !isAllianz,
          type: 'money2',
          valueGetter: (row) => row.werte?.map((w) => w.abs4),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="abs4"
              formatter={formatMoney2}
              darkMode={darkMode}
            />
          )
        },
        {
          field: 'abs5',
          header: kondiDefinition.zuschlAbs5Label,
          headerTip: kondiDefinition.zuschlAbs5Tip,
          cellPadding: 0,
          visible: (row) => !isAllianz,
          type: 'money2',
          valueGetter: (row) => row.werte?.map((w) => w.abs5),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="abs5"
              formatter={formatMoney2}
              darkMode={darkMode}
            />
          )
        },
        {
          type: 'string',
          field: 'rabattgruppe',
          header: 'EK-RG',
          headerTip: 'EK-Rabattgruppe des Staffelwertes',
          cellPadding: 0,
          valueGetter: (row) => row.werte?.map(formatRabattgruppe),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="rabattgruppe"
              formatter={formatRabattgruppe}
              darkMode={darkMode}
              align="left"
            />
          )
        },
        {
          type: 'string',
          field: 'ebeneRabattgruppe',
          header: isAllianz ? 'EK-RG PE' : 'EK-RG Standort',
          headerTip: isAllianz ? 'EK-Rabattgruppe der Preisebene' : 'EK-Rabattgruppe des Standorts',
          valueGetter: (row) => row.ebeneRabattgruppe?.name,
          body: (row) => (
            <AppLink
              path={AppPaths.EkRabattgruppenPflegeFn({ id: row.ebeneRabattgruppe?.id })}
              text={row.ebeneRabattgruppe?.name}
            />
          )
        },
        {
          type: 'string',
          field: 'artikelRabattgruppe',
          header: 'EK-RG Artikel',
          headerTip: 'EK-Rabattgruppe des Artikels',
          valueGetter: (row) => row.artikelRabattgruppe?.name,
          body: (row) => (
            <AppLink
              path={AppPaths.EkRabattgruppenPflegeFn({ id: row.artikelRabattgruppe?.id })}
              text={row.artikelRabattgruppe?.name}
            />
          )
        },
        {
          type: 'boolean',
          field: 'abgeschlossen',
          header: 'Abgeschlossen',
          align: 'center',
          tooltip: null
        }
      ]
    }),
    [darkMode, isAllianz, isPreisEbeneMode, kondiDefinition]
  )

  const columns = useArtikelColumns(columnProps)

  const tableActions = useMemo(
    (): DataTableAction[] => [
      {
        icon: 'info',
        tooltip: 'Archivierter Artikel',
        handler: () => {},
        check: (row) => (row.archiviert ? Permission.ENABLED : Permission.HIDDEN)
      },
      {
        icon: readOnly ? 'visibility' : 'edit',
        tooltip: readOnly ? 'Anzeigen' : 'Bearbeiten',
        getLink: (data: any) => {
          return AppPaths.EkPreisPflegeFn(preislisteId, data.id)
        },
        check: () => (editMode ? Permission.ENABLED : Permission.HIDDEN)
      },
      {
        icon: 'arrow_forward',
        tooltip: 'Artikel öffnen',
        getLink: (data: any) => data.artikel && AppPaths.ArtikelFn(data.artikel?.id)
      }
    ],
    [editMode, preislisteId, readOnly]
  )

  const openLabelDialog = useCallback(() => {
    openLabelsEdit({
      artikel: Array.from(selected).map((select) => select.artikel),
      callback: {
        then: () => reload()
      }
    })
  }, [openLabelsEdit, selected, reload])

  const actions = useMemo(
    () =>
      [
        {
          role: UserRoles.STAMMDATEN_EDITOR,
          text: 'Labels',
          icon: 'label',
          variant: 'contained',
          disabled: selected == null || selected.size === 0,
          onClick: openLabelDialog
        },
        ...safeArray(bottomActions)
      ] as Action[],
    [bottomActions, openLabelDialog, selected]
  )

  const getRowStyle = useCallback(
    (row: EkPreiseMasseBearbeitenEintragExJson) => {
      if (row.artikel.archiviert) {
        return getArchiviertRowStyle(row?.artikel.archiviert)
      }
      const style: CSSProperties = {}
      if (row.artikel.importFehlerData || row.artikel.importFehlerPreiseEk)
        style.backgroundColor = red[darkMode ? 800 : 100]
      if (row.neu) style.color = blueGrey[darkMode ? 400 : 600]
      return style
    },
    [darkMode]
  )

  return (
    <DataTableCard
      name="EkPreisMassenPflegeTable"
      filterMode="both"
      title={title}
      columns={columns}
      value={value}
      dense
      paging
      selectCol
      selectMode="multi"
      selected={selected}
      onSelect={onSelect}
      bottomActions={actions}
      stickyColumsLeft={isPreisEbeneMode ? 4 : 6}
      actions={tableActions}
      rowStyle={getRowStyle}
      localStateName={`EkPreisMassenPflege${isPreisEbeneMode ? 'SM' : ''}Table`}
      loading={loading}
      emptyMessage={
        (loading && 'Lade...') ||
        emptyMessage ||
        (editMode && preisEbeneId == null && (
          <>
            Es wurden noch keine Preise erstellt.
            <br />
            Wechseln Sie die Preisebene, um Preise zu erstellen.
          </>
        )) ||
        'Die Preisliste ist leer'
      }
    />
  )
}
