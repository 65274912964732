import { Grid } from '@mui/material'
import { LieferantAdresseJson } from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import { StaticField } from '@utils/ui/fields/StaticField'

export type LieferantAdresseProps = {
  adresse: LieferantAdresseJson
  label: string
  dz?: boolean
}

export const LieferantAdresse = ({ adresse, label, dz }: LieferantAdresseProps) => {
  return (
    <CardEx title={label}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <StaticField label="Strasse" value={adresse?.strasse} />
        </Grid>
        <Grid item xs={2}>
          <StaticField label="Land" value={adresse?.land} />
        </Grid>
        <Grid item xs={2}>
          <StaticField label="Plz" value={adresse?.plz} />
        </Grid>
        <Grid item xs={4}>
          <StaticField label="Ort" value={adresse?.ort} />
        </Grid>

        <Grid item xs={12} />

        <Grid item xs={2}>
          <StaticField label="Postfach" value={adresse?.postfach} />
        </Grid>
        <Grid item xs={2}>
          <StaticField label="Postfach Plz" value={adresse?.postfachPlz} />
        </Grid>
        <Grid item xs={2}>
          <StaticField label="Postfach Ort" value={adresse?.postfachOrt} />
        </Grid>

        <Grid item xs={12} />

        <Grid item xs={6}>
          <StaticField label="E-Mail" value={adresse?.email} empty />
        </Grid>
        <Grid item xs={6}>
          <StaticField label="Internet" value={adresse?.internet} empty />
        </Grid>
        <Grid item xs={6}>
          <StaticField label="Telefon" value={adresse?.tel1} empty />
        </Grid>
        <Grid item xs={6}>
          <StaticField label="Fax" value={adresse?.fax} empty />
        </Grid>
      </Grid>
    </CardEx>
  )
}
