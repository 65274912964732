import Grid from '@mui/material/Grid2'
import { formatWarengruppe } from '@one/components/common/WarengruppeCell'
import { ZeArtikelJson } from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import { StaticField } from '@utils/ui/fields/StaticField'

export const ArtikelWarengruppenZe = ({ artikel }: { artikel: ZeArtikelJson }) => (
  <CardEx title="Warengruppen">
    <Grid container spacing={2} direction="column" size={{ xs:6 }}>
      <Grid>
        <StaticField
          label="BT-Warengruppe"
          value={(artikel as ZeArtikelJson)?.btWarengruppe}
          fullWidth
        />
      </Grid>
      <Grid>
        <StaticField
          label="Hagebau Warengruppe"
          value={formatWarengruppe(artikel?.hageWarengruppe)}
          fullWidth
        />
      </Grid>
    </Grid>
  </CardEx>
)
