import { AddBox, InfoOutlined, Search } from '@mui/icons-material'
import { Chip, Typography } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { api } from '@one/api'
import { HkmEnum } from '@one/enums/HkmEnum'
import { LieferantDisplayJson, LieferantUebersichtJson } from '@one/typings/apiTypings'
import { ApiExclusive, useApiCaller } from '@utils/apicaller'
import { useEnums } from '@utils/enums'
import { AppContext } from '@utils/ui/App/AppContext'
import { IconButton } from '@utils/ui/Buttons/IconButton'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { TooltipEx } from '@utils/ui/TooltipWrapper'
import { AutocompleteAsync } from '@utils/ui/fields/AutocompleteAsync'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { compareStrings, ifString, restartTimer, sortArray } from '@utils/utils'
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { LieferantSearchDialog } from './LieferantSearchDialog'
import Grid from '@mui/material/Grid2'

export interface LieferantFieldProps {
  label: string
  onChange?: (e: any) => void
  name?: string
  id?: string
  placeholder?: string
  value?: any
  valueAsId?: boolean
  error?: string | boolean
  className?: string
  defaultSugg?: any[]
  disabled?: boolean
  fullWidth?: boolean
  required?: boolean
  modus: 'zentral' | 'dezentral' | 'alle'
  disableActions?: boolean
  loading?: boolean
  asLink?: boolean
  helperText?: string
}

export const LieferantField = ({
  label,
  onChange,
  id,
  name,
  value,
  valueAsId,
  placeholder,
  loading,
  error,
  className,
  defaultSugg,
  modus,
  disabled,
  required,
  fullWidth,
  disableActions,
  helperText = '',
  asLink
}: LieferantFieldProps) => {
  const [apiCall] = useApiCaller(api)
  const navigate = useNavigate()

  const timerRef = useRef()

  const { isAllianzMitglied } = useContext(AppContext)

  const [DlgAnker, dlgShow] = useDialogAnker()

  const { et } = useEnums()

  const multipleRef = useRef<boolean>(false)

  const [auchGeloeschte, setAuchGeloeschte] = useState(undefined)

  const onOpenSearch = useCallback(
    (event) => {
      event.stopPropagation()
      event.preventDefault()
      dlgShow((open, onClose) => (
        <LieferantSearchDialog
          open={open}
          onClose={onClose((sel) => {
            onChange({ name, value: sel })
          })}
          defaultZentral={modus === 'zentral' || modus === 'alle'}
          defaultDezentral={modus === 'dezentral' || modus === 'alle'}
          disableZentral={modus !== 'alle'}
          disableDezentral={modus !== 'alle'}
          auchGeloeschte={auchGeloeschte}
        />
      ))
    },
    [auchGeloeschte, dlgShow, modus, name, onChange]
  )

  const onChangeInt = useCallback(
    (e) => {
      if (onChange) {
        if (e.target?.value?.lieferant) {
          e.target.value = e.target.value.lieferant
        }
        onChange(e)
      }
    },
    [onChange]
  )

  const optionLabel = useCallback((v) => {
    const lieferant: LieferantDisplayJson = v.lieferant && v.info ? v.lieferant : v
    if (lieferant?.notanlage) {
      return `${lieferant.nummer} (Notanlage)`
    }
    return `${lieferant.nummer} ${lieferant.name1} (${lieferant.ort || ''} ${lieferant.land || ''})`
  }, [])

  const renderItem = useCallback(
    (v) => {
      const lieferant: LieferantDisplayJson = v.lieferant && v.info ? v.lieferant : v
      const geloescht = lieferant.geloescht || lieferant.geloeschtDz
      const gesperrt = lieferant.gesperrt || lieferant.gesperrtDz
      const name = lieferant.notanlage ? 'Notlieferant' : lieferant.name1
      return (
        <Grid container spacing={1}>
          <Grid>{lieferant.nummer}</Grid>
          <Grid>
            {name}
            <small>
              {' '}
              - {lieferant.ort ?? ''} {lieferant.land ?? ''}
            </small>
          </Grid>
          {multipleRef.current || modus === 'alle' ? (
            <Grid>
              <Chip
                label={et(HkmEnum.SuchLieferantTyp, lieferant.typ)}
                size="small"
                variant="filled"
              />
            </Grid>
          ) : null}
          {(gesperrt || geloescht) && (
            <Grid>
              <Chip label="gelöscht/gesperrt" size="small" variant="filled" />
            </Grid>
          )}
          {/* <Grid item flexGrow={1} /> Aktuell fehlt für ERP häufig die Anzahl der gelisteten Artikel & Performance
          {lieferant.anzahlGelistet > 0 && (
            <Grid item>
              <small>{` ~${lieferant.anzahlGelistet} Artikel`}</small>
            </Grid>
          )} */}
        </Grid>
      )
    },
    [et, modus]
  )

  const tooltip = useMemo(() => {
    if (value == null) {
      return null
    }
    const geloescht = value.geloescht || value.geloeschtDz
    const gesperrt = value.gesperrt || value.gesperrtDz
    return (
      <>
        <Typography variant="subtitle1">
          {value.nummer} {value.name1}
        </Typography>
        <Typography variant="subtitle2">{value.strasse}</Typography>
        {value.plz && value.ort && value.land && (
          <Typography variant="subtitle2">
            {`${value.plz} ${value.ort}`} {value.land}
          </Typography>
        )}
        <Typography variant="subtitle2">
          Quelle: {et(HkmEnum.SuchLieferantTyp, value.typ)}
        </Typography>
        {gesperrt && <Typography variant="subtitle2">gesperrt</Typography>}
        {geloescht && <Typography variant="subtitle2">gelöscht</Typography>}
      </>
    )
  }, [et, value])

  const queryLieferant = useCallback(
    ({ reason, query, onSuccess, onError }) => {
      if (reason === 'query') {
        searchRef.current = query
        const pnummer = query.match(/^\d+$/) ? Number(query) : null
        const pname = pnummer == null ? query.toUpperCase() : null
        apiCall<LieferantUebersichtJson>({
          method: 'POST',
          rest: '/lieferant/uebersichtAnzeigen/search',
          exclusive: ApiExclusive.CANCEL,
          data: {
            nummer: pnummer,
            name: pname,
            auchGeloeschte,
            forAbo: isAllianzMitglied && (modus === 'zentral' || modus === 'alle'),
            zentral: !isAllianzMitglied && (modus === 'zentral' || modus === 'alle'),
            dezentral: modus === 'dezentral' || modus === 'alle',
            mitStatistik: false // Aktuell fehlt für ERP häufig die Anzahl der gelisteten Artikel & Performance
          },
          onSuccess: (data) => {
            const dupILN = new Set<string>()
            multipleRef.current = data.searcher?.result.find((r) => {
              const dup = dupILN.has(r.iln)
              dupILN.add(r.iln)
              return dup
            }) != null
            onSuccess(
              data.searcher &&
                sortArray<LieferantDisplayJson>(data.searcher.result, [
                  (a, b) => compareStrings(a.name1, b.name1),
                  (a, b) => compareStrings(a.iln, b.iln)
                ])
            )
          },
          onError
        })
      }
    },
    [apiCall, auchGeloeschte, isAllianzMitglied, modus]
  )

  const onLieferantInfoClick = useCallback(() => {
    navigate(AppPaths.LieferantFn(value.id))
  }, [navigate, value])

  const optionValue = (v) => {
    const lieferant = v.lieferant ? v.lieferant : v
    return valueAsId ? lieferant?.id : lieferant
  }

  const isOptionEqualToValue = (o, v) => {
    if (valueAsId) {
      const lieferant = o.lieferant ? o.lieferant : o
      return lieferant?.id === v
    }
    return o === v || (o.lieferant || o).id === (v.lieferant || v).id
  }

  const searchRef = useRef<any>()
    useEffect(() => {
      if(searchRef.current && auchGeloeschte!==null) {
        searchRef.current.search()
      }
    }, [auchGeloeschte]);

  return (
    <span>
      <AutocompleteAsync
        id={id}
        label={label}
        name={name}
        loading={loading}
        emptyText={placeholder}
        error={!!error}
        helperText={ifString(error) ?? helperText}
        disabled={disabled}
        value={value}
        onChange={onChangeInt}
        renderItem={renderItem}
        queryOptions={queryLieferant}
        className={className}
        fullWidth={fullWidth}
        required={required}
        loadResetToggle={modus}
        actions={
          disableActions || disabled
            ? null
            : [
              <Checkbox
                key="ag"
                checkedIcon={<AddBox />}
                size="small"
                title="Auch gelöschte"
                checked={auchGeloeschte}
                onChange={() => {
                  setAuchGeloeschte(!auchGeloeschte)
                }}
              />,
              <IconButton
                key="search"
                size="small"
                name={name && `${name}-search`}
                icon={<Search />}
                onClick={onOpenSearch}
                tooltip="Ausführliche Suche"
              />
            ]
        }
        optionLabel={optionLabel}
        optionValue={optionValue}
        isOptionEqualToValue={isOptionEqualToValue}
        groupBy={(o) => (o && o.info ? o.info : 'Suchergebnis')}
        defaultOptions={defaultSugg}
        apiRef={searchRef}
        endAdornments={
          tooltip &&
          (value.id ? (
            <span style={{ cursor: 'help' }}>
              <IconButton
                icon={<InfoOutlined />}
                size="small"
                onClick={onLieferantInfoClick}
                disabled={!asLink}
                tooltip={tooltip}
                tooltipPlacement="top-start"
              />
            </span>
          ) : (
            <TooltipEx title={tooltip} arrow placement="top-start">
              <InfoOutlined />
            </TooltipEx>
          ))
        }
      />
      <DlgAnker />
    </span>
  )
}
