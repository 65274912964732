import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { AppPaths } from '@one/AppPaths'
import { api } from '@one/api'
import { DzLieferantAnzeigenJson, UseCaseStateJson } from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { Action } from '@utils/ui/Action'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { CardEx } from '@utils/ui/CardEx'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { HistoricDataPager } from '@utils/ui/HistoricDataPager'
import { IndexPanel, IndexPanelItem } from '@utils/ui/IndexPanel'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { asNumber } from '@utils/utils'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ImportError } from './details/ImportError'
import { LieferantAdresse } from './details/LieferantAdresse'
import { LieferantBezeichnung } from './details/LieferantBezeichnung'
import { LieferantDetailsAbo } from './details/LieferantDetailsAbo'
import { LieferantDetailsEkKonditionen } from './details/LieferantDetailsEkKonditionen'
import { LieferantDetailsListen } from './details/LieferantDetailsListen'
import { LieferantErpSperre } from './details/LieferantErpSperre'
import { LieferantenLieferbedingung } from './details/LieferantenLieferbedingung'

interface LieferantenLink {
  id: number
  isDz: boolean
}

export const DzLieferantView = () => {
  const { id: _id, revision: _revision } = useContext(RouteContext) as any
  const id = asNumber(_id)
  const revision = asNumber(null)
  const navigate = useNavigate()

  const [error, setError] = useState<UseCaseStateJson>()
  const [lieferantData, setLieferantData] = useState<DzLieferantAnzeigenJson>()
  const [currentRevision, setCurrentRevision] = useState(revision)

  const lieferant = lieferantData?.lieferant

  const [apiCall, apiBusy] = useApiCaller(api)

  const fetchData = useCallback(() => {
    apiCall<DzLieferantAnzeigenJson>({
      method: 'GET',
      rest: `/lieferant/dz/open`,
      params: { id, revision },
      onErrorMsg: `Lieferant mit ID "${id}" konnte nicht geladen werden.`,
      onError: (err) => setError(err),
      onSuccess: (data) => {
        setLieferantData(data)
        if (data.revisions) {
          if (revision) {
            const current = data.revisions.find((item) => item.id == revision)
            setCurrentRevision(current?.id)
          } else {
            const current = data.revisions.find((item) => item.bis == null)
            setCurrentRevision(current?.id)
          }
        }
      }
    })
  }, [apiCall, id, revision])

  const lastIdRef = useRef<any>()
  useEffect(() => {
    if (id !== lastIdRef.current) {
      lastIdRef.current = id
      fetchData()
    }
  }, [fetchData, id])

  const reload = useCallback(() => fetchData(), [fetchData])

  const topActions = useMemo(
    () =>
      [
        {
          navlink:
            lieferantData?.zeLieferantId &&
            AppPaths.LieferantListungFn(lieferantData.zeLieferantId),
          text: `Zum Listungs-Lieferant`,
          visible: lieferantData?.zeLieferantId != null
        }
      ].filter(Boolean) as Action[],
    [lieferantData?.zeLieferantId]
  )

  const historicNavigate = useCallback(
    (revision) => {
      navigate(AppPaths.LieferantFn(id, revision.id))
    },
    [id, navigate]
  )

  const header = useMemo(
    () => (
      <Grid container spacing={3}>
        <Grid>
          <Typography variant="h6">{`ERP-Lieferant "${
            lieferant?.hauptAdresse ? lieferant?.hauptAdresse?.name1 : ''
          }"${lieferant?.eigenlistung ? ' (Eigenlistung)' : ''}`}</Typography>
        </Grid>
        <Grid>
          <HistoricDataPager
            revisions={lieferantData?.revisions}
            currentRevision={currentRevision}
            to={historicNavigate}
          />
        </Grid>
      </Grid>
    ),
    [
      lieferant?.hauptAdresse,
      lieferant?.eigenlistung,
      lieferantData?.revisions,
      currentRevision,
      historicNavigate
    ]
  )

  const content = [
    {
      id: 'bezeichnung',
      label: 'Lieferant',
      exclude: lieferant == null,
      body: () => <LieferantBezeichnung lieferant={lieferant} type="dz" />
    },
    {
      id: 'hadresse',
      label: 'Hauptadresse',
      exclude: lieferant?.hauptAdresse == null,
      body: () => <LieferantAdresse adresse={lieferant.hauptAdresse} dz label="Hauptadresse" />
    },
    {
      id: 'aadresse',
      label: 'Abholadresse',
      exclude: lieferant?.abholAdresse == null,
      body: () => <LieferantAdresse adresse={lieferant.abholAdresse} dz label="Abholadresse" />
    },
    {
      id: 'sperren',
      label: 'Sperren',
      exclude: lieferant == null,
      body: () => <LieferantErpSperre lieferant={lieferant} />
    },
    {
      id: 'lieferbedingungen',
      label: 'Lieferbedingungen',
      exclude: lieferant == null,
      body: () => <LieferantenLieferbedingung lieferant={lieferant} />
    },
    {
      id: 'abo',
      label: 'Lieferantenabos',
      exclude: lieferantData?.lieferantAbo == null,
      body: () => <LieferantDetailsAbo abo={lieferantData.lieferantAbo} loading={apiBusy} />
    },
    {
      id: 'lieferantenlisten',
      label: 'Lieferantenlisten',
      exclude: !(lieferantData?.manuelleListen.length > 0),
      body: () => <LieferantDetailsListen listen={lieferantData.manuelleListen} loading={apiBusy} />
    },
    {
      id: 'ekKonditionenDefinition',
      label: 'EK-Kalkulationsschema',
      exclude: lieferantData?.ekKonditionenDefinition == null || lieferant.eigenlistung,
      body: () => (
        <LieferantDetailsEkKonditionen
          ekKonditionen={lieferantData.ekKonditionenDefinition}
          loading={apiBusy}
        />
      )
    },
    {
      id: 'importError',
      label: 'Importfehler',
      exclude: lieferantData?.importError == null,
      body: () => <ImportError error={lieferantData.importError} />
    }
  ] as IndexPanelItem[]

  return (
    <StatePlane wait={apiBusy} error={error}>
      <Frame space>
        <FrameRow>
          <CardEx
            backLink
            overviewLink={AppPaths.LieferantUebersicht}
            topActions={topActions}
            header={header}
            contentStyle={{ paddingBottom: 0 }}
          />
        </FrameRow>
        <FrameBody>
          <IndexPanel content={content} />
        </FrameBody>
        <FrameRow>
          <ButtonRow>
            <ReloadButton onClick={reload} />
          </ButtonRow>
        </FrameRow>
      </Frame>
    </StatePlane>
  )
}
